App.CheckCounter = {
  eventSelector: '.js-select-field',
  fieldToCountSelector: '.js-select-field input:checked, input.js-select-field:checked',
  labelSelector: '.js-count-label',
  sectionSelector: '.js-count-section',

  registerEvents() {
    $(this.eventSelector).on('change', $.proxy(this.updateLabel, this));
    $(this.eventSelector).trigger('change');
  },
  updateLabel(event) {
    const section = $(event.target).closest(this.sectionSelector);
    const label = section.find(this.labelSelector);
    const checkedInputs = section.find(this.fieldToCountSelector);
    const labelText = (checkedInputs.length > 0)
      ? ` - ${checkedInputs.length} attributes selected`
      : '';

    return label.text(labelText);
  },
};
App.CheckCounter.registerEvents();
