// handler for add/remove item to/from cart
// eslint-disable-next-line no-unused-vars
function selectionItemClickHandler(url, el, csrfToken, callbackFn) {
  const pk = el.attr('data-pk');
  const postData = { pk, quantity: 1, csrfmiddlewaretoken: csrfToken };

  $.post(
    url,
    postData,
    (data) => {
      if (data.employee_selection_count <= 0) {
        $('.js-employees-selection-count').text('');
      }
      else {
        $('.js-employees-selection-count').text(data.employee_selection_count.toString());
      }

      callbackFn(el, data.employee_selection_count);
    },
    'json',
  );

  return false;
}

// handler for add/remove all filtered items to/from cart
// eslint-disable-next-line no-unused-vars
function selectionClickHandler(url, operation, csrfToken, subset, callbackFn) {
  if (subset) {
    // split string of primary keys into array
    const prefixPksArr = subset.split(',');
    const postData = { pks: prefixPksArr.toString(), csrfmiddlewaretoken: csrfToken };

    $.post(
      url,
      postData,
      (data) => {
        const array = subset.split(',');

        if (data.employee_selection_count <= 0) {
          $('.js-employees-selection-count').text('');
        }
        else {
          $('.js-employees-selection-count').text(data.employee_selection_count.toString());
        }

        for (let index = 0; index < array.length; index += 1) {
          const el = $(`.select-item[data-pk=${array[index]}]`);
          callbackFn(el);
        }
      },
      'json',
    );
  }

  return false;
}

// change remove-item to add-item row
function changeClassRemove(el) {
  if (el.hasClass('remove-item')) {
    el.addClass('glyphicon-ok add-item');
    el.removeClass('glyphicon-remove remove-item');
    el.closest('tr').removeClass('warning');
  }
}

// change add-item to remove-item row
function changeClassAdd(el) {
  if (el.hasClass('add-item')) {
    el.addClass('glyphicon-remove remove-item');
    el.removeClass('glyphicon-ok add-item');
    el.closest('tr').addClass('warning');
  }
}

// change css in table row
// eslint-disable-next-line no-unused-vars
function changeClass(el) {
  if (el.hasClass('add-item')) {
    changeClassAdd(el);
  }
  else {
    changeClassRemove(el);
  }
}
