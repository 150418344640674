$(() => {
  const foreigner = $('#id_foreigner');
  const bannedNightShifts = $('#id_banned_night_shifts');
  const medicalCheckCategory = $('#id_medical_check_category');
  const medicalCheckType = $('#id_medical_check_type');
  const medicalCheckPeriodicity = $('#id_medical_check_periodicity');
  const medicalCheckPeriodicityRow = $('.js-medical-check-periodicity-row');

  function isForeignerChecked() {
    try {
      const residencePermit = $('#id_residence_permit_until');
      const residencePermitLabel = $('#employee-form').find('.js-residence-permit');
      if (foreigner.length > 0) {
        if (foreigner.is(':checked')) {
          residencePermit.slideDown(50);
          residencePermitLabel.slideDown(50);
        }
        else {
          residencePermit[0].value = '';
          residencePermit.slideUp(50);
          residencePermitLabel.slideUp(50);
        }
      }
    }
    catch (e) {
      // This happens if form has foreigner but no residence_permit_until,
      // i.e. in Vendor new employee form.
      // Ignore.
    }
  }

  if (!foreigner.prop('disabled')) {
    if (foreigner.length) {
      foreigner.click(isForeignerChecked);
      isForeignerChecked();
    }
  }

  function shiftWorkHandler() {
    const shiftWorkType = $('#id_shift_work_type').parent('div');
    const shiftWorkTypeLabel = $('.shiftWorkTypeLabel');

    if ($(this).val() === '0') {
      shiftWorkType.slideUp();
      shiftWorkTypeLabel.slideUp();
      // NOTE:
      // bootstrap-material-design uses selectize:
      // https://github.com/FezVrasta/bootstrap-material-design#selectizejs
      // To set the value of the selectize: goo.gl/08qsZo
      // Simple way to set the value of selectize:

      // quick fix for order of initializaton.
      $('#id_shift_work_type').selectize({});
      $('#id_shift_work_type')[0].selectize.setValue('');
    }
    else {
      shiftWorkType.slideDown();
      shiftWorkTypeLabel.slideDown();
    }
  }

  $('#id_shift_work').on('change', shiftWorkHandler).change();

  function isBannedNightShiftsChecked() {
    const bannedNightShiftsUntil = $('#employee-form').find('#id_banned_night_shifts_until');
    const bannedNightShiftsUntilLabel = $('#employee-form').find('.banned_night_shifts_label');
    const bannedNightShiftsNote = $('#employee-form').find('#id_banned_night_shifts_note');
    if (bannedNightShifts.is(':checked')) {
      bannedNightShiftsUntil.slideDown(50);
      bannedNightShiftsUntilLabel.slideDown(50);
      bannedNightShiftsNote.slideDown(50);
    }
    else {
      bannedNightShiftsUntil.slideUp(50);
      bannedNightShiftsUntilLabel.slideUp(50);
      bannedNightShiftsUntil.val('');
      bannedNightShiftsNote.slideUp(50);
      bannedNightShiftsNote.val('');
    }
  }

  bannedNightShifts.on('change', isBannedNightShiftsChecked).change();

  function isMedicalCheckExitType() {
    if (medicalCheckType.val() === '4') {
      App.selectSetValue(medicalCheckPeriodicity, '');
      medicalCheckPeriodicityRow.slideUp();
    }
    else {
      medicalCheckPeriodicityRow.slideDown();
    }
  }

  medicalCheckType.on('change', isMedicalCheckExitType).change();

  function medicalCheckCategoryChange() {
    App.toggleField(medicalCheckPeriodicity, medicalCheckCategory.val() === '2');
    if (medicalCheckCategory.val() === '1') {
      App.selectSetValue(medicalCheckPeriodicity, '72');
    }
    else if (medicalCheckCategory.val() === '2') {
      App.selectSetValue(medicalCheckPeriodicity, '24');
    }
  }

  medicalCheckCategory.on('change', medicalCheckCategoryChange);
  medicalCheckCategoryChange();


  function addWarningMessage(text) {
    const message = `${"<div class='alert alert-warning alert-dismissable'>" +
            "<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>×</button>"}${
      text
    }</div>`;
    $('#messages').append(message);
  }

  function endDateCheck() {
    const endDate = $.datepicker.parseDate('dd.mm.yy', $('#id_end_date').val());
    const dateInTwoWeeks = new Date();
    dateInTwoWeeks.setDate(dateInTwoWeeks.getDate() + 14);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    $('#messages .alert').remove();
    if (today <= endDate && endDate <= dateInTwoWeeks) {
      const text = 'Are you sure this employee will be ending in the next 14 days? If you save employee with ' +
                'this End Date, a lot of people will be notified by email about this employee ' +
                'leaving right away!';
      addWarningMessage(text);
    }
  }

  $('#id_end_date').on('change', endDateCheck);

  const disabledFrom = $('.js-disabled-from');
  const workDisability = $('.js-work-disability');

  if (disabledFrom.length && workDisability.length) {
    disabledFrom.change(() => {
      workDisability.prop('checked', disabledFrom.val().length > 0);
    });

    workDisability.click(() => {
      if (workDisability.prop('checked')) {
        disabledFrom.prop('required', true);
        disabledFrom.closest('.form-group').addClass('required');
      }
      else {
        disabledFrom.val('');
        disabledFrom.prop('required', false);
        disabledFrom.closest('.form-group').removeClass('required');
      }
    });
  }
});

const onPositionChange = function onPositionChange() {
  const form = $(this).closest('form');
  const request = $.ajax({
    type: 'POST',
    url: form.attr('action'),
    data: form.serialize(),
    dataType: 'html',
  });

  request.done((response) => {
    App.Replace.fromDataBySelector(form, `#${form.attr('id')}`, response);
    App.Employees.registerEvents();
    App.init();
  });
  request.fail(() => {
    console.log('fail'); // eslint-disable-line no-console
  });
};

const onDeactivateInOktaChange = function onDeactivateInOktaChange() {
  const deactivateInOktaImmediately = $('.js-deactivate-in-okta-immediately input');
  const doNotDeactivateInOkta = $('.js-do-not-deactivate-in-okta input');

  if (deactivateInOktaImmediately.is(':checked')) {
    doNotDeactivateInOkta.prop('checked', false);
    $('.js-do-not-deactivate-in-okta').slideUp();
  }
  else {
    $('.js-do-not-deactivate-in-okta').slideDown();
  }

  if (doNotDeactivateInOkta.is(':checked')) {
    deactivateInOktaImmediately.prop('checked', false);
    $('.js-deactivate-in-okta-immediately').slideUp();
  }
  else {
    $('.js-deactivate-in-okta-immediately').slideDown();
  }
};


App.Employees = (function init() {
  const registerEvents = function registerEvents() {
    $('.js-position').on('change', onPositionChange);
    $('.js-deactivate-in-okta-immediately').on('change', onDeactivateInOktaChange);
    $('.js-do-not-deactivate-in-okta').on('change', onDeactivateInOktaChange);
  };

  return {
    registerEvents,
  };
}());

App.ContractTypeChange = (function init() {
  const initialStartDateValue = $('#id_start_date').val();
  const initialContractStartDateValue = $('#id_contract_start_date').val();
  const changePersonalNumber = $('.js-change-personal-number');
  const changePersonalNumberCheckbox = $('#id_change_personal_number');
  if (!changePersonalNumberCheckbox.is(':checked')) {
    changePersonalNumber.slideUp();
    changePersonalNumberCheckbox.prop('checked', false);
  }

  const maternityLeaveInput = $('#id_maternity_leave');
  const parentalLeaveInput = $('#id_parental_leave');
  const maternityLeaveRow = $('#js-maternity-leave-row');
  const parentalLeaveRow = $('#js-parental-leave-row');

  /*
    Only show one leave fields. Maternity or parental.
   */
  const hideLeave = function hideLeave(hideElement, checked) {
    if (checked) {
      hideElement.hide();
      hideElement.find('input.datepicker').val('');
      hideElement.find('input:checkbox').prop('checked', false);
    }
    else {
      hideElement.show();
    }
  };

  const onleaveChange = function onleaveChange(actionElement, hideElement) {
    // deal with initial state
    hideLeave(hideElement, actionElement.prop('checked'));

    // bind event listener
    actionElement.on('change', function () {
      hideLeave(hideElement, this.checked);
    });
  };
  const onDatesChange = function onDatesChange() {
    const startDateField = $('#id_start_date');
    const contractStartDateField = $('#id_contract_start_date');

    const startDateValue = startDateField.val();
    const contractStartDateValue = contractStartDateField.val();

    if (initialStartDateValue !== startDateValue &&
      initialContractStartDateValue !== contractStartDateValue) {
      changePersonalNumber.slideDown();
      changePersonalNumberCheckbox.prop('checked', true);
    }
    else {
      if (changePersonalNumberCheckbox.is(':checked')) {
        changePersonalNumberCheckbox.prop('checked', false);
      }
      changePersonalNumber.slideUp();
    }
  };

  const registerEvents = function registerEvents() {
    onleaveChange(maternityLeaveInput, parentalLeaveRow);
    onleaveChange(parentalLeaveInput, maternityLeaveRow);
    $('.js-start-date').on('change', onDatesChange);
    $('.js-contract-start-date').on('change', onDatesChange);
  };

  return {
    registerEvents,
  };
}());
