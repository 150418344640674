const PromocodeEdit = {

  /* CONSTANTS */
  classModal: 'js-modal-edit-promocode',
  classModalTitlePK: 'js-modal-edit-promocode--title-pk',
  classModalNote: 'js-modal-edit-promocode--note',
  classModalForm: 'js-modal-edit-promocode--form',
  classPromocodeHistoryRecord: 'js-promocode-history-record',
  classPromocodeHistoryRecordEditButton: 'js-promocode-history-record--edit-button',
  classPromocodeHistoryRecordNote: 'js-promocode-history-record--note',
  attrPromocodeHistoryRecordDataID: 'data-pk',
  attrPromocodeHistoryRecordEditURL: 'data-edit-url',

  /* METHODS */
  assignEvents() {
    $(`.${this.classPromocodeHistoryRecordEditButton}`).on('click', (event) => {
      const promocodeHistoryRecord = $(event.currentTarget).closest(
        `.${this.classPromocodeHistoryRecord}`,
      );

      const promocodeHistoryRecordPK = promocodeHistoryRecord.attr(
        this.attrPromocodeHistoryRecordDataID,
      );

      const promocodeHistoryRecordNote = promocodeHistoryRecord.find(
        `.${this.classPromocodeHistoryRecordNote}`,
      ).text();

      const modal = $(`.${this.classModal}`);

      const editURL = promocodeHistoryRecord.attr(
        this.attrPromocodeHistoryRecordEditURL,
      );

      modal.find(`.${this.classModalTitlePK}`).text(promocodeHistoryRecordPK);
      modal.find(`.${this.classModalNote}`).text(promocodeHistoryRecordNote);
      modal.find(`.${this.classModalForm}`).attr('action', editURL);
    });
  },

  init() {
    this.assignEvents();
  },

};

$(document).on('ready', () => {
  PromocodeEdit.init();
});
