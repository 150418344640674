App.CategoryForm = (function init() {
  // @TODO - Refactor, almost same setters, toggles and hider are
  // used in ticket.js - refactor an unify.
  const setFieldValue = function setFieldValue(id, val) {
    const el = $(`#${id}`);
    const value = val || '';

    const selectize = el[0].selectize;
    const trix = App.TrixHelpers.getTrixEditorForInput(el);

    // set value
    if (selectize) {
      if (value instanceof Array) {
        selectize.clear(false);
        $.each(value, (i, v) => {
          selectize.addItem(v, false);
        });
      }
      else {
        selectize.setValue(value.toString());
      }
    }
    else if (trix) {
      App.TrixHelpers.replaceContentInEditor(trix, value);
    }
    else {
      el.val(value);
    }
    // care about material design layout
    el.parent('div').removeClass('has-error');
    if (value !== '' && value.length !== 0) {
      el.parent('div').removeClass('is-empty');
    }
    else {
      el.parent('div').addClass('is-empty');
    }
  };

  const setFieldBool = function setFieldBool(id, value) {
    const el = $(`#${id}`);

    el.prop('checked', value);
  };

  const enableFields = function enableFields() {
    // Teams
    const idTeamVal = $('#id_team').val();
    const idTeamMembersVal = $('#id_team_members').val();
    const idCustomTeamVal = $('#id_custom_team').val();
    const inheritTeams = $('#id_inherit_teams').prop('checked');

    if (inheritTeams) {
      App.toggleField('#id_team', true);
      App.toggleField('#id_team_members', true);
      App.toggleField('#id_team_managers', true);
      App.toggleField('#id_custom_team', true);
    }
    else {
      App.toggleField('#id_team', idCustomTeamVal || idTeamMembersVal);
      App.toggleField('#id_team_members', idTeamVal || idCustomTeamVal);
      App.toggleField('#id_team_managers', idTeamVal || idCustomTeamVal);
      App.toggleField('#id_custom_team', idTeamVal || idTeamMembersVal);
    }

    // Visibility
    const inheritVisibility = $('#id_inherit_visibility').prop('checked');
    App.toggleField('#id_public', inheritVisibility);
    App.toggleField('#id_visibility', inheritVisibility);
    App.toggleField('#id_category_teams_custom', inheritVisibility);
    App.toggleField('#id_category_teams_regular', inheritVisibility);

    // Messages
    const inheritMessages = $('#id_inherit_messages').prop('checked');
    App.toggleField('#id_template', inheritMessages);
    App.toggleField('#id_message', inheritMessages);
    App.TrixHelpers.toggleEditor('id_template', inheritMessages);
    App.TrixHelpers.toggleEditor('id_message', inheritMessages);

    // Custom fields
    const inheritCustomFields = $('#id_inherit_custom_fields').prop('checked');
    App.toggleField('#id_custom_fields', inheritCustomFields);
  };

  const enableInheritance = function enableInheritance() {
    const inheritGroups = ['teams', 'visibility', 'messages', 'custom_fields'];
    const disable = $('#id_parent_category').val() === '';

    inheritGroups.forEach((id) => {
      if (disable) {
        $(`#id_inherit_${id}`).prop('checked', false);
      }
      $(`#id_inherit_${id}`).prop('disabled', disable);
      $(`#id_inherit_${id}`).closest('.form-group').toggle(!disable);
    });
  };

  const doInheritValues = function doInheritValues() {
    const parentCategoryVal = $('#id_parent_category').val();
    const inheritTeams = $('#id_inherit_teams').prop('checked');
    const inheritVisibility = $('#id_inherit_visibility').prop('checked');
    const inheritMessages = $('#id_inherit_messages').prop('checked');
    const inheritCustomFields = $('#id_inherit_custom_fields').prop('checked');

    enableInheritance();

    if (parentCategoryVal !== '') {
      $.ajax({
        type: 'get',
        url: `/api/v1/tickets/category/?id=${parentCategoryVal}`,
        success(data) {
          if (data.length > 0) {
            const d0 = data[0];
            if (inheritTeams) {
              setFieldValue('id_team', d0.team ? d0.team.id : '');
              setFieldValue('id_custom_team', d0.custom_team ? d0.custom_team : '');
            }
            if (inheritVisibility) {
              setFieldBool('id_public', d0.public);
              setFieldValue('id_visibility', d0.visibility);
              setFieldValue('id_category_teams_regular', d0.regular_teams);
              setFieldValue('id_category_teams_custom', d0.custom_teams);
            }
            if (inheritMessages) {
              setFieldValue('id_message', d0.message);
              setFieldValue('id_template', d0.template);
            }
            if (inheritCustomFields) {
              setFieldValue('id_custom_fields', d0.custom_fields);
            }

            enableFields();
            $('#id_visibility').change();
          }
        },
      });
    }
    else {
      enableFields();
    }
  };

  const onMessageBlur = function onMessageBlur(event) {
    if ($.trim($(this).context.textContent).length === 0) {
      $(event.target).val('');
    }
  };

  const onTeamMembersChange = function onTeamMembersChange() {
    enableFields();

    const teamMembers = $('#id_team_members')[0].selectize;
    const teamManagers = $('#id_team_managers')[0].selectize;

    const teamMembersVal = teamMembers.items;
    const teamManagersOptions = Object.keys(teamManagers.options);
    const added = $(teamMembersVal).not(teamManagersOptions).get();
    const removed = $(teamManagersOptions).not(teamMembersVal);

    // add new options
    for (let i = 0; i < added.length; i += 1) {
      const value = added[i];
      const item = teamMembers.getItem(value);
      teamManagers.addOption({ value, text: item.text() });
    }

    // remove options
    for (let i = 0; i < removed.length; i += 1) {
      const value = removed[i];
      teamManagers.removeOption(value);
    }
  };

  const onVisibilityChange = function onVisibilityChange() {
    const noTeamsVisibilities = ['', App.categoryVisibilityAll];

    if (noTeamsVisibilities.includes($('#id_visibility').val())) {
      $('#category-teams-wrapper').hide();
    }
    else {
      $('#category-teams-wrapper').show();
    }
    $('#id_category_teams_custom').change();
  };

  const addAnimatedScrollToHistory = function addAnimatedScrollToHistory() {
    $('html, body').animate({
      scrollTop: $('#category-history-table').offset().top,
    }, 800);
  };

  const registerEvents = function registerEvents() {
    $('#id_parent_category').on('change', doInheritValues);
    $('#id_inherit_teams').on('change', doInheritValues);
    $('#id_inherit_visibility').on('change', doInheritValues);
    $('#id_inherit_messages').on('change', doInheritValues);
    $('#id_inherit_custom_fields').on('change', doInheritValues);

    $("[input='id_message'], [input='id_template']").on('blur', onMessageBlur);
    $('#id_team').on('change', enableFields);
    $('#id_custom_team').on('change', enableFields);
    $('#id_team_members').on('change', onTeamMembersChange);
    $('#id_visibility').on('change', onVisibilityChange);
    $('#category_history').on('click', addAnimatedScrollToHistory);

    $(document).ready(() => {
      enableFields();
      enableInheritance();
      /* Since we use selectize by default on all selectboxes, user can submit an empty value.
       * If so, value will be prepopulated by default value and "This field is required." error
       * will be shown below. If we would fire `onchange` event for visibility field, bootstrap
       * would react accordingly - Error message would disappear.
       *
       * Therefore on init, we want to launch just onVisibilityChange function, which is oncahnge
       * event handler for visibility field, not onchange event itself.
       */
      onVisibilityChange();
    });
  };

  return {
    registerEvents,
  };
}());

App.CustomTeamForm = (function init() {
  const onTeamMembersChange = function onTeamMembersChange() {
    const teamMembers = $('#id_team_members')[0].selectize;
    const teamManagers = $('#id_team_managers')[0].selectize;

    const teamMembersVal = teamMembers.items;
    const teamManagersOptions = Object.keys(teamManagers.options);
    const added = $(teamMembersVal).not(teamManagersOptions).get();
    const removed = $(teamManagersOptions).not(teamMembersVal);

    // add new options
    for (let i = 0; i < added.length; i += 1) {
      const value = added[i];
      const item = teamMembers.getItem(value);
      teamManagers.addOption({ value, text: item.text() });
    }

    // remove options
    for (let i = 0; i < removed.length; i += 1) {
      const value = removed[i];
      teamManagers.removeOption(value);
    }
  };

  const deleteCustomTeam = function deleteCustomTeam() {
    if (!$(this).closest('tr').hasClass('row_thead')) {
      $(this).closest('tr').addClass('warning');
    }
    const customTeamName = $(this).data().customteamname;
    const url = $(this).data().url;
    const pk = $(this).data().pk;
    $('.modal-header .custom-team-name').html(customTeamName);
    $('#delete-custom-team-modal-form').attr('action', url);
    $('#delete-custom-team-modal-form').attr('pk', pk);
  };

  const deleteCustomTeamModalSubmit = function deleteCustomTeamModalSubmit(event) {
    event.preventDefault();
    const $form = $(this);

    $.ajax({
      type: $form.attr('method'),
      url: $form.attr('action'),
      data: $form.serialize(),

      success(data) {
        $.each(data.ids, (index, value) => {
          const tr = $(`a[data-pk=${value}]`).closest('tr');
          tr.fadeOut('normal', () => {
            tr.remove();
          });
        });
        $('#modal-delete-custom-team').modal('hide');
        App.addMessage('success', data.message);
      },
    });
  };

  const registerEvents = function registerEvents() {
    $('#id_team_members').on('change', onTeamMembersChange);
    $('.delete-custom-team').on('click', deleteCustomTeam);
    $('#delete-custom-team-modal-form').on('submit', deleteCustomTeamModalSubmit);
  };

  return {
    registerEvents,
  };
}());
