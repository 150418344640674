App.AssignYourself = {
  buttonSelector: '.js-assign-yourself',

  registerEvents() {
    $(this.buttonSelector).on('click', $.proxy(this.selectByKey, this));
  },

  selectByKey(event) {
    const $this = $(event.currentTarget);
    const selectbox = $($this.data('target'));
    const changeToValue = $this.data('key');

    selectbox.get(0).selectize.setValue(changeToValue);
  },
};
