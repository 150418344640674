const AjaxGetter = {
  columns: 'default',
  tableSelector: '.js-ajax-table',
  registerEvents() {
    $(this.tableSelector).one('change', $.proxy(this.handleAjaxTable, this));
  },
  handleAjaxTable(event) {
    const table = $(event.target).closest('table');
    const teamId = table.data('team-id');
    const employeePk = $(event.target).attr('pk');
    const rowId = `${teamId}_${employeePk}`;
    const row = $(`.employee_row_${rowId}`);
    const status = $('#id_status').val();
    $.ajax({
      url: AjaxGetter.url,
      data: {
        team: teamId,
        employee: employeePk,
        lower_bound: AjaxGetter.lower_bound,
        upper_bound: AjaxGetter.upper_bound,
        columns: AjaxGetter.columns,
        status,
      },
      dataType: 'html',
      success(data) {
        $(data).insertAfter(row);
      },
    });
  },
};

// eslint-disable-next-line no-unused-vars
const AttendanceApproval = (function AttendanceApproval() {
  function approveAttendance() {
    const container = $(this).closest('.form-group');
    const checkboxesChecked = container.find('.js-employee-attendance:checked');
    const arrChecked = $.map(checkboxesChecked, checkbox => $(checkbox).data('employee'));
    $('.js-employee-ids-approved').val(arrChecked.join(','));
    const checkboxesUnchecked = container.find('.js-employee-attendance:not(:checked)');
    const arrUnchecked = $.map(checkboxesUnchecked, checkbox => $(checkbox).data('employee'));
    $('.js-employee-ids-rejected').val(arrUnchecked.join(','));
  }

  function init() {
    $('.js-approve-attendance').click(approveAttendance);
  }

  return {
    init,
  };
}());

// eslint-disable-next-line no-unused-vars
const PayrollExport = (function PayrollExport() {
  function selectUnselectAll() {
    const action = $(this).data('action');
    $('.js-team-select').prop('checked', action === 'check');
  }

  function init() {
    $('.js-select-all').click(selectUnselectAll);
    $('.js-medicover-deduction-button').on('click', () => {
      $('.js-medicover-from').val($('#id_day_0').val());
      $('.js-medicover-to').val($('#id_day_1').val());
    });
    $('.js-multisport-deduction-button').on('click', () => {
      $('.js-multisport-from').val($('#id_day_0').val());
      $('.js-multisport-to').val($('#id_day_1').val());
    });
  }
  return {
    init,
  };
}());

// eslint-disable-next-line no-unused-vars
const HolidayPlanner = (function HolidayPlanner() {
  const selectorDateFrom = '.js-date-from';
  const selectorDateTo = '.js-date-to';
  const selectorSave = '.js-holiday-planner-save';
  const selectorDelete = '.js-holiday-planner-delete';
  function init() {
    $(`${selectorDateFrom}, ${selectorDateTo}`).datepicker('option', 'minDate', 0);
    $(`${selectorDateFrom}, ${selectorDateTo}`).datepicker('option', 'maxDate', 90);
    $(`${selectorSave}, ${selectorDelete}`).click(() => $(`${selectorDateFrom}`).val() !== '' && $(`${selectorDateTo}`).val() !== '');
  }
  return {
    init,
  };
}());
