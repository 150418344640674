$('.js-delete-job-position').on('click', function onClick(e) {
  e.preventDefault();
  $('#modal-delete-job-position')
    .modal('show')
    .find('.modal-content-wrapper')
    .load($(this).attr('href'), () => {
      $('.js-delete-job-position-form').on('submit', function onSubmit() {
        $('button', $(this)).attr('disabled', true);
      });
    });
});
