/* eslint-disable camelcase, no-unused-vars */
// handler for add/remove item to/from cart
function cart_item_click_handler(delta, prefix, url, el, csrf_token, callback_fn) {
  const pk = el.attr('data-pk');
  const cart_number = $('.cart-number');
  let count = parseInt((cart_number.text()), 10) || 0;

  // delta == 0 means that we emptying whole cart
  const post_data = (delta === 0)
    ? { prefix, csrfmiddlewaretoken: csrf_token }
    : { pk: `${prefix}-${pk}`, quantity: 1, csrfmiddlewaretoken: csrf_token };

  $.post(url, post_data, () => {
    // delta === 0 means that we emptying whole cart
    if (delta === 0) {
      count = '';
    }
    else {
      count += delta;
      if (count <= 0) {
        count = '';
      }
    }
    cart_number.text(count);
    callback_fn(el, count);
  }, 'json');

  return false;
}

// handler for add/remove all filtered items to/from cart
function cart_selection_click_handler(prefix, url, operation, csrf_token, subset, callback_fn) {
  if (subset) {
    const cart_number = $('.cart-number');
    let count = parseInt((cart_number.text()), 10) || 0;

    // split string of primary keys into array with prefixes
    const prefix_pks_arr = subset.split(',').map(el => `${prefix}-${el}`);
    const post_data = {
      prefix,
      pks: prefix_pks_arr.toString(),
      operation,
      csrfmiddlewaretoken: csrf_token,
    };

    $.post(url, post_data, (data) => {
      const array = subset.split(',');
      count = parseInt(data.counts[prefix], 10) <= 0 ? '' : parseInt(data.counts[prefix], 10);
      cart_number.text(count);
      array.forEach((item) => {
        const el = $(`.select-item[data-pk=${item}]`);
        callback_fn(el, count);
      });
    }, 'json');
  }

  return false;
}

// change remove-item to add-item row
function change_class_remove(el) {
  if (el.hasClass('remove-item')) {
    el.addClass('glyphicon-ok add-item');
    el.removeClass('glyphicon-remove remove-item');
    el.closest('tr').removeClass('warning');
  }
}

// change add-item to remove-item row
function change_class_add(el) {
  if (el.hasClass('add-item')) {
    el.addClass('glyphicon-remove remove-item');
    el.removeClass('glyphicon-ok add-item');
    el.closest('tr').addClass('warning');
  }
}

// change css in table row
function change_class(el) {
  if (el.hasClass('add-item')) {
    change_class_add(el);
  }
  else {
    change_class_remove(el);
  }
}
/* eslint-enable camelcase, no-unused-vars */
