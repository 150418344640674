App.ClipBoardHelper = (function ClipBoardHelper() {
  const setTooltip = function setTooltip(button, message) {
    $(button).attr('data-original-title', message);
  };

  const hideTooltip = function hideTooltip(button, delay) {
    setTimeout(() => {
      $(button).tooltip('hide');
    }, delay);
  };

  const init = function init() {
    const selectElements = $('.selectize-control:not(.binary-select)');
    selectElements.each(function handleElement() {
      const btn = $('<button type="button">');
      btn.attr('data-original-title', 'copy to clipboard');
      btn.attr('class', 'material-icons clipboard-btn js-tooltip');
      btn.text('content_copy');

      const select = $(this);
      select.append(btn);
      // eslint-disable-next-line no-undef
      const clip = new Clipboard(btn[0], {
        text() {
          const dataItems = select.find('.item');
          const pattern = /^-*/g;
          let data = '';
          for (let i = 0; i < dataItems.length; i += 1) {
            data = `${data + dataItems[i].innerText} `;
          }

          data = data.replace(pattern.exec(data)[0], '');
          data = data.trim();
          return data || '\0';
        },
      });

      clip.on('success', (e) => {
        setTooltip(e.trigger, 'Copied!');
        $(e.trigger).tooltip('show');
        hideTooltip(e.trigger, 1000);
      });

      clip.on('error', (e) => {
        setTooltip(e.trigger, 'using an old browser? Cmd-C to copy');
        $(e.trigger).tooltip('show');
        hideTooltip(e.trigger, 3000);
      });
      selectElements.find('.js-tooltip').tooltip({
        trigger: 'manual',
        placement: 'bottom',
      });
    });
  };
  return {
    init,
  };
}());

// This function is not initialized
// used for employee tabs content
// if needed please initialize this in main.js
App.ClipBoardEmployee = (function ClipBoardEmployee() {
  // eslint-disable-next-line func-names
  const setMessage = function (element, message) {
    element.append(message);
  };

  const hideMessage = function hideMessage(element, delay) {
    setTimeout(() => {
      $(element).remove();
    }, delay);
  };

  const init = function init() {
    const successDelay = 1000;
    const errorDelay = 4000;
    const selectItem = '.js-clipboard-item';
    const selectElements = $('.js-clipboard-group');
    selectElements.each(function handleElement() {
      const btn = $('<button type="button">');
      btn.attr('class', 'material-icons clipboard-btn');
      btn.text('content_copy');

      const select = $(this);
      select.append(btn);
      // eslint-disable-next-line no-undef
      const clipboard = new Clipboard(btn[0], {
        text() {
          const dataItems = select.find(selectItem);
          const pattern = /^-*/g;
          let data = '';
          for (let i = 0; i < dataItems.length; i += 1) {
            data = `${data + dataItems[i].innerText}`;
          }
          data = data.replace(pattern.exec(data)[0], '');
          data = data.trim();
          return data || '\0';
        },
      });

      clipboard.on('error', () => {
        setMessage(select.find(selectItem), '<i class="msg-clip msg-success">Copied!</i>');
        hideMessage(select.find(`${selectItem} i`), successDelay);
      });

      clipboard.on('success', () => {
        setMessage(select.find(selectItem), '<i class="msg-clip msg-danger">using an old browser? Ctr+C to copy</i>');
        hideMessage(select.find(`${selectItem} i`), errorDelay);
      });
    });
  };
  return {
    init,
  };
}());


// usage example:
// <input id="MyId" type="url" value="SomeValue" />
// <button class="js-clip-btn" data-clipboard-target="#MyId">Copy</button>
// @TODO: not working with Clipboardjs < v 2.0.0

// App.ClipboardSimple = (function ClipboardSimple() {
//   const clipbordBtn = '.js-clip-btn';
//   const init = function init() {
//     new ClipboardJS(clipbordBtn);
//   };
//   return {
//     init,
//   };
// }());

