const refreshStats = function refreshStats() {
  const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'];

  function createChart(ctx, data) {
    return new Chart(ctx, {
      type: 'bar',
      data: {
        labels: data.map(a => MONTHS[new Date(a.month).getMonth()]),
        datasets: [
          {
            label: 'Employees left',
            data: data.map(a => a.end_count),
            backgroundColor: 'red',
          },
          {
            label: 'Employees hired',
            data: data.map(a => a.start_count),
            backgroundColor: 'green',
          },
        ],
      },
    });
  }

  function onSuccess(data) {
    const sortedData = data.sort((a, b) => new Date(a.month) - new Date(b.month));
    $('#empChart').replaceWith('<canvas id="empChart"></canvas>');
    const ctx = document.getElementById('empChart').getContext('2d');
    createChart(ctx, sortedData);
  }

  function loadChart() {
    $.get({
      url: $('.chart-container').attr('data-url'),
      data: {
        year: $('#year').val(),
        vendor: $('#vendor').val(),
      },
      success: onSuccess,
    });
  }

  function registerEvents() {
    if ($('#empChart').length === 0) {
      return false;
    }
    $(document).on('ready', loadChart);
    $('#year').on('change', loadChart);
    $('#vendor').on('change', loadChart);

    return true;
  }

  return {
    registerEvents,
  };
};
refreshStats().registerEvents();
