App.OrgChart = (function init() {
  const _margin = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20,
  };
  let _root = {};
  let _nodes = [];
  const _counter = 0;
  let _svgroot = null;
  let _svg = null;
  let _tree = null;
  let _diagonal = null;
  let _lineFunction = null;
  let _loadFunction = null;
  /* Configuration */
  const _duration = 750; /* Duration of the animations */
  const _rectW = 215; /* Width of the rectangle */
  const _rectH = 70; /* Height of the rectangle */
  const _rectSpacing = 10; /* Spacing between the rectangles */
  let _fixedDepth = 80; /* Height of the line for child nodes */
  let _mode = 'line'; /* Choose the values "line" or "diagonal" */
  let _callerNode = null;
  const _maxTextLength = 34;
  let _initId = null;
  const _officeSpaceButton = {
    width: 20,
    height: 20,
  };

  const defLinearGradient = function defLinearGradient(id, x1, y1, x2, y2, stopsdata) {
    const gradient = _svgroot.append('svg:defs')
      .append('svg:linearGradient')
      .attr('id', id)
      .attr('x1', x1)
      .attr('y1', y1)
      .attr('x2', x2)
      .attr('y2', y2)
      .attr('spreadMethod', 'pad');

    $.each(stopsdata, (index, value) => {
      gradient.append('svg:stop')
        .attr('offset', value.offset)
        .attr('stop-color', value.color)
        .attr('stop-opacity', value.opacity);
    });
  };

  const defBoxShadow = function defBoxShadow(id) {
    const filter = _svgroot.append('svg:defs')
      .append('svg:filter')
      .attr('id', id).attr('height', '150%')
      .attr('width', '150%');

    filter.append('svg:feOffset')
      .attr('dx', '2').attr('dy', '2').attr('result', 'offOut'); // how much to offset
    filter.append('svg:feGaussianBlur')
      .attr('in', 'offOut').attr('result', 'blurOut').attr('stdDeviation', '2'); // stdDeviation is how much to blur
    filter.append('svg:feBlend')
      .attr('in', 'SourceGraphic').attr('in2', 'blurOut').attr('mode', 'normal');
  };

  const appendCellTexts = function appendCellTexts(nodes) {
    nodes.append('text')
      .attr('x', _rectW / 2)
      .attr('y', _rectH / 3.8) // defines height from cell bottom, where text nodes are placed.
      .attr('dy', '1em')
      .attr('text-anchor', 'top')
      .style('cursor', d => ((d.children || d._children || d.hasChild) ? 'pointer' : 'default'))
      .each(function cellText(d) {
        let jobPosition = Object.prototype.hasOwnProperty.call(d.desc, 'job_position') ? d.desc.job_position : null;
        let teamName = Object.prototype.hasOwnProperty.call(d.desc, 'team_name') ? d.desc.team_name : null;
        let text = Object.prototype.hasOwnProperty.call(d.desc, 'name') ? d.desc.name : '-';

        const stringRows = [];

        while (text.length > _maxTextLength) {
          stringRows.push({ text: text.slice(0, _maxTextLength), type: 'name' });
          text = text.substr(_maxTextLength);
        }

        stringRows.push({ text, type: 'name' });

        if (jobPosition !== null) {
          while (jobPosition.length > _maxTextLength) {
            stringRows.push({ text: jobPosition.slice(0, _maxTextLength), type: 'jobPosition' });
            jobPosition = jobPosition.substr(_maxTextLength);
          }
          stringRows.push({ text: jobPosition, type: 'jobPosition' });
        }

        if (teamName !== null) {
          while (teamName.length > _maxTextLength) {
            stringRows.push({ text: teamName.slice(0, _maxTextLength), type: 'teamName' });
            teamName = teamName.substr(_maxTextLength);
          }
          stringRows.push({ text: teamName, type: 'teamName' });
        }

        for (let i = 0; i < stringRows.length; i += 1) {
          d3.select(this).append('tspan')
            .text(stringRows[i].text)
            .attr('dy', i ? '1.4em' : 0)
            .attr('x', _rectW / 2)
            .attr('text-anchor', 'middle')
            .attr('class', `${stringRows[i].type} tspan${i}`);
        }
      });
  };

  const update = function update(source) {
    // Compute the new tree layout.
    _nodes = _tree.nodes(_root).reverse();
    const links = _tree.links(_nodes);

    // Normalize for fixed-depth.
    _nodes.forEach((d) => {
      d.y = d.depth * _fixedDepth; // eslint-disable-line no-param-reassign
    });

    // Update the nodes
    const node = _svg.selectAll('g.node')
      .data(_nodes, d => d.id || (d.id + _counter)); // eslint-disable-line no-param-reassign

    // Enter any new nodes at the parent's previous position.
    const nodeEnter = node.enter().append('g')
      .attr('class', 'node')
      .attr('transform', () => `translate(${source.x0},${source.y0})`)
      .on('click', nodeclick); // eslint-disable-line no-use-before-define

    nodeEnter.append('rect')
      .attr('width', _rectW)
      .attr('height', _rectH)
      .attr('fill', d => (_initId === parseInt(d.id, 10) ? '#F9091B' : '#898989'))
      .attr('filter', 'url(#boxShadow)');

    nodeEnter.append('rect')
      .attr('width', _rectW)
      .attr('height', _rectH)
      .attr('id', d => d.id)
      .attr('fill', d => ((d.children || d._children || d.hasChild) ? 'url(#gradientchilds)' : 'url(#gradientnochilds)'))
      .style('cursor', d => ((d.children || d._children || d.hasChild) ? 'pointer' : 'default'))
      .attr('class', 'box');

    appendCellTexts(nodeEnter);

    nodeEnter.each(function handleNode(d) {
      if (Object.prototype.hasOwnProperty.call(d, 'email') && d.email !== null) {
        const hrefParams = encodeURI(`${String(d.id)} ${d.email}`);
        const href = `https://kiwi.officespacesoftware.com/visual-directory/search/${hrefParams}`;

        const link = d3.select(this).append('a')
          .attr('class', 'orgchart-office-space-link')
          .attr('href', href)
          .attr('target', '_blank')
          .on('click', () => {
            d3.event.preventDefault();
            window.open(jQuery(d3.event.target).closest('.orgchart-office-space-link').attr('href'));
          });

        link.append('rect')
          .attr('width', _officeSpaceButton.width)
          .attr('height', _officeSpaceButton.height)
          .attr('data-employee-id', el => el.id)
          .attr('fill', _officeSpaceButton.fill)
          .style('cursor', 'pointer')
          .attr('class', 'orgchart-office-space-button')
          .attr('x', _rectW - _officeSpaceButton.width)
          .attr('y', '0');

        link.append('svg:image')
          .attr('class', 'orgchart-office-space-button-icon')
          .attr('width', _officeSpaceButton.width - (_officeSpaceButton.width * 0.2))
          .attr('height', _officeSpaceButton.height - (_officeSpaceButton.height * 0.2))
          .attr('x', _rectW - _officeSpaceButton.width + (_officeSpaceButton.width * 0.1))
          .attr('y', _officeSpaceButton.height * 0.1)
          .attr('xlink:href', '/static/images/officespace-icon.svg');
      }
    });

    // Transition nodes to their new position.
    const nodeUpdate = node.transition()
      .duration(_duration)
      .attr('transform', d => `translate(${d.x},${d.y})`);

    nodeUpdate.select('rect.box')
      .attr('fill', d => (
        (d.children || d._children || d.hasChild)
          ? 'url(#gradientchilds)'
          : 'url(#gradientnochilds)'),
      );

    // Transition exiting nodes to the parent's new position.
    node.exit().transition()
      .duration(_duration)
      .attr('transform', () => `translate(${source.x},${source.y})`)
      .remove();

    // Update the links
    const link = _svg.selectAll('path.link')
      .data(links, d => d.target.id);

    if (_mode === 'line') {
      // Enter any new links at the parent's previous position.
      link.enter().append('path', 'g')
        .attr('class', 'link')
        .attr('d', (d) => {
          const uLine = (function lineIt(el) {
            const xSource = Object.prototype.hasOwnProperty.call(el.source, 'x0')
              ? el.source.x0
              : el.source.x;
            const ySource = Object.prototype.hasOwnProperty.call(el.source, 'y0')
              ? el.source.y0
              : el.source.y;
            const uLineData = [{ x: xSource + parseInt(_rectW / 2, 10), y: ySource + _rectH + 2 },
              { x: xSource + parseInt(_rectW / 2, 10), y: ySource + _rectH + 2 },
              { x: xSource + parseInt(_rectW / 2, 10), y: ySource + _rectH + 2 },
              { x: xSource + parseInt(_rectW / 2, 10), y: ySource + _rectH + 2 }];

            return uLineData;
          }(d));
          return _lineFunction(uLine);
        });

      // Transition links to their new position.
      link.transition()
        .duration(_duration)
        .attr('d', (d) => {
          const uLine = (function lineItNew(el) {
            return [
              {
                x: el.source.x + parseInt(_rectW / 2, 10),
                y: el.source.y + _rectH,
              },
              {
                x: el.source.x + parseInt(_rectW / 2, 10),
                y: el.target.y - (_margin.top / 2),
              },
              {
                x: el.target.x + parseInt(_rectW / 2, 10),
                y: el.target.y - (_margin.top / 2) },
              {
                x: el.target.x + parseInt(_rectW / 2, 10),
                y: el.target.y,
              },
            ];
          }(d));

          return _lineFunction(uLine);
        });

      // Transition exiting nodes to the parent's new position.
      link.exit().transition()
        .duration(_duration)
        .attr('d', (d) => {
          /* This is needed to draw the lines right back to the caller */
          const uLine = (function LineToCaller() {
            if (_callerNode === null) {
              _callerNode = { x: 0, y: 0 };
            }
            else if (!Object.prototype.hasOwnProperty.call(_callerNode, 'x')) {
              _callerNode.x = 0;
            }

            return [
              { x: _callerNode.x + parseInt(_rectW / 2, 10), y: _callerNode.y + _rectH + 2 },
              { x: _callerNode.x + parseInt(_rectW / 2, 10), y: _callerNode.y + _rectH + 2 },
              { x: _callerNode.x + parseInt(_rectW / 2, 10), y: _callerNode.y + _rectH + 2 },
              { x: _callerNode.x + parseInt(_rectW / 2, 10), y: _callerNode.y + _rectH + 2 },
            ];
          }(d));

          return _lineFunction(uLine);
        })
        .each('end', () => {
          _callerNode = null; /* After transition clear the caller node variable */ 
        });
    }
    else if (_mode === 'diagonal') {
      // Enter any new links at the parent's previous position.
      link.enter().insert('path', 'g')
        .attr('class', 'link')
        .attr('x', _rectW / 2)
        .attr('y', _rectH / 2)
        .attr('d', () => {
          const o = {
            x: source.x0,
            y: source.y0,
          };
          return _diagonal({
            source: o,
            target: o,
          });
        });

      // Transition links to their new position.
      link.transition()
        .duration(_duration)
        .attr('d', _diagonal);

      // Transition exiting nodes to the parent's new position.
      link.exit().transition()
        .duration(_duration)
        .attr('d', () => {
          const o = {
            x: source.x,
            y: source.y,
          };
          return _diagonal({
            source: o,
            target: o,
          });
        })
        .remove();
    }

    // Stash the old positions for transition.
    /* eslint-disable no-param-reassign */
    _nodes.forEach((d) => {
      d.x0 = d.x;
      d.y0 = d.y;
    });
    /* eslint-enable no-param-reassign */
  };

  // Toggle children on click.
  const nodeclick = function nodeclick(d) {
    /* eslint-disable no-param-reassign */
    if (!d.children && !d._children && d.hasChild) {
      // If there are no childs --> Try to load child nodes
      _loadFunction(d, (childs) => {
        const response = { id: d.id,
          desc: d.desc,
          children: childs.result,
        };

        response.children.forEach((child) => {
          if (!_tree.nodes(d)[0]._children) {
            _tree.nodes(d)[0]._children = [];
          }

          child.x = d.x;
          child.y = d.y;
          child.x0 = d.x0;
          child.y0 = d.y0;
          _tree.nodes(d)[0]._children.push(child);
        });

        if (d.children) {
          _callerNode = d;
          d._children = d.children;
          d.children = null;
        }
        else {
          _callerNode = null;
          d.children = d._children;
          d._children = null;
        }

        update(d);
      });
    }
    else {
      if (d.children) {
        _callerNode = d;
        d._children = d.children;
        d.children = null;
      }
      else {
        _callerNode = d;
        d.children = d._children;
        d._children = null;
      }

      update(d);
    }
    /* eslint-enable no-param-reassign */
  };

  // Redraw for zoom
  const redraw = function redraw() {
    _svg.attr('transform', `translate(${d3.event.translate})` +
                                ` scale(${d3.event.scale.toFixed(1)})`);
  };

  const initTree = function initTree(options) { // init id is ID where initial zoom will be made.
    const uOpts = $.extend({
      id: '',
      data: {},
      modus: 'line',
      loadFunc() {},
    }, options);
    _loadFunction = uOpts.loadFunc;
    _mode = uOpts.modus;
    _root = uOpts.data;
    const id = uOpts.id;

    _fixedDepth = (_mode === 'line') ? 90 : 110;

    $(id).html(''); // Reset
    const width = $(id).innerWidth() - _margin.left - _margin.right;
    const height = $(id).innerHeight() - _margin.top - _margin.bottom;

    // computing node sizes
    _tree = d3.layout.tree().nodeSize([_rectW + _rectSpacing, _rectH + _rectSpacing]);

    /* Basic Setup for the diagonal function. _mode = "diagonal" */
    _diagonal = d3.svg.diagonal()
      .projection(d => [
        d.x + (_rectW / 2),
        d.y + (_rectH / 2),
      ]);

    /* Basic setup for the line function. _mode = "line" */
    _lineFunction = d3.svg.line()
      .x(d => d.x)
      .y(d => d.y)
      .interpolate('linear');

    const uChildWidth = parseInt((_root.children.length * _rectW) / 2, 10);
    const zoom = d3.behavior.zoom().scaleExtent([0.15, 3]).on('zoom', redraw);

    _svgroot = d3.select(id).append('svg')
      .attr('width', width)
      .attr('height', height)
      .call(zoom); // also defines max zooming

    const translateX = parseInt(
      uChildWidth + ((width - (uChildWidth * 2)) / 2) - (_margin.left / 2),
      10,
    );
    const translateY = 20;
    _svg = _svgroot.append('g')
      .attr('transform', `translate(${translateX},${translateY})`);

    let uStops;
    uStops = [
      { offset: '0%', color: '#797979', opacity: 1 },
      { offset: '100%', color: '#656565', opacity: 1 },
    ];
    defLinearGradient('gradientnochilds', '0%', '0%', '0%', '100%', uStops);

    uStops = [
      { offset: '0%', color: '#06a596', opacity: 1 },
      { offset: '100%', color: '#009688', opacity: 1 },
    ];
    defLinearGradient('gradientchilds', '0%', '0%', '0%', '100%', uStops);

    defBoxShadow('boxShadow');

    if (Object.prototype.hasOwnProperty.call(options, 'initId')) {
      _initId = parseInt(options.initId, 10);
      setTimeout(() => {
        function recursiveSearch(item, identification) {
          if (item.id === identification) {
            return item;
          }
          else if (Object.prototype.hasOwnProperty.call(item, 'children') && item.children.length > 0) {
            for (let i = item.children.length - 1; i >= 0; i -= 1) {
              const result = recursiveSearch(item.children[i], identification);

              if (result) {
                return result;
              }
            }
          }
          return false;
        }

        const targetCell = recursiveSearch(options.data, options.initId);
        const coords = {
          x: (width / 2) - (_rectW / 2) - targetCell.x,
          y: (height / 2) - (_rectH / 2) - targetCell.y,
        };

        _svg.transition().duration(450).attr('transform', `translate(${String(coords.x)},${String(coords.y)})`);

        zoom.translate([coords.x, coords.y]);
      }, _duration);
    }
    else {
      zoom.translate([
        parseInt(uChildWidth + ((width - (uChildWidth * 2)) / 2) - (_margin.left / 2), 10),
        20,
      ]);
    }

    _root.x0 = 0; // the root is already centered
    _root.y0 = height / 2; // draw & animate from center

    // _root.children.forEach(collapse); // Would hide nested children on reload.

    update(_root);

    d3.select(id).style('height', height + _margin.top + _margin.bottom);
  };

  return { initTree };
}());
