const AttendanceType = {
  publicHoliday: 2,
  homeOffice: 3,
  sickday: 6,
  toilBankHoliday: 25,
};

// eslint-disable-next-line no-unused-vars
const AttendanceDays = (function AttendanceDays() {
  const selectorAttendance = '.js-attendance';
  const selectorDay = '.js-attendance-day';
  const selectorEmptyForm = '.empty-attendance-form';
  const selectorDayGroup = '.js-attendance-day-group';
  const selectorFieldTimeFrom = '.js-attendance-field-time-from input';
  const selectorFieldTimeTo = '.js-attendance-field-time-to input';
  const selectorFieldType = '.js-attendance-field-type select';
  const selectorOutOfOffice = '.js-attendance-field-out-of-office input';
  const fieldNameDelete = 'DELETE';
  const fieldNameDay = 'day';
  const fieldNameTimeFrom = 'time_from';
  const fieldNameTimeto = 'time_to';
  const fieldNameType = 'type';
  const selectorButtonAdd = '.js-attendance-btn-add';
  const selectorButtonDelete = '.js-attendance-btn-delete';
  const selectorClassToDelete = '.to-delete'; // CLASS because of addClass()
  let attendanceData = {};

  function removeErrorsFromCopyForm() {
    $(`${selectorEmptyForm} .has-error`).removeClass('has-error');
  }

  function hasCorrectTimeFormat(timeValue) {
    if (typeof timeValue !== 'string') {
      return false;
    }
    return timeValue.match(/^\d\d?:\d\d$/) !== null;
  }

  function adjustManagementForm(formSet) {
    const totalLength = formSet.find(selectorDay).length;
    const formsetPrefix = formSet.attr('data-formset-prefix');
    formSet.find(`#id_${formsetPrefix}-TOTAL_FORMS`).val(totalLength);
  }

  function adjustAllManagementForms() {
    $(selectorAttendance).find(selectorDayGroup).each(function adjustForms() {
      adjustManagementForm($(this));
    });
  }

  function getNextPrefix(oldRow) {
    const oldFormPrefix = oldRow.attr('data-form-prefix');
    const newFormPrefix = oldFormPrefix.split('-');
    newFormPrefix[newFormPrefix.length - 1] = String(
      Number(
        newFormPrefix[newFormPrefix.length - 1],
      ) + 1,
    );
    return newFormPrefix.join('-');
  }

  function initAddButton(button) {
    button.click(function onAddClick() {
      const currentRow = $(this).closest(selectorDay);
      const nextFormPrefix = getNextPrefix(currentRow);
      const existingRow = $(`[data-form-prefix="${nextFormPrefix}"]`);
      const newRow = existingRow.length === 1
        ? existingRow
        : $(`${selectorEmptyForm} ${selectorDay}`).clone(true);
      // eslint-disable-next-line no-use-before-define
      initNewRow(newRow, currentRow, nextFormPrefix, existingRow.length === 1);
      const previousEndTime = currentRow.find(selectorFieldTimeTo).val();
      let newStartingTime = '';
      let newEndingTime = '';
      if (hasCorrectTimeFormat(previousEndTime)) {
        newStartingTime = previousEndTime;
        newEndingTime = previousEndTime.split(':');
        const newHour = Number(newEndingTime[0]) + 1;
        if (newHour >= 23) {
          newEndingTime = '23:59';
        }
        else {
          newEndingTime[0] = String(newHour);
          newEndingTime = newEndingTime.join(':');
        }
      }
      const newRowTimeFromField = newRow.find(selectorFieldTimeFrom);
      const newRowTimeToField = newRow.find(selectorFieldTimeTo);
      newRowTimeFromField.val(newStartingTime);
      newRowTimeToField.val(newEndingTime);
      newRowTimeFromField.timepicker();
      newRowTimeToField.timepicker();

      // clone options for type
      const typeOptions = currentRow.find(`${selectorFieldType} > option`).clone();
      newRow.find(selectorFieldType).empty().append(typeOptions);

      newRow.find(selectorFieldType).val(1); // set to Office
      newRow.find(selectorButtonAdd).removeClass('hidden');
      currentRow.after(newRow);
      $(this).addClass('hidden');
      adjustAllManagementForms();
    });
  }

  function initDeleteButton(button) {
    button.click(function onDeleteClick() {
      const formSet = $(this).closest(selectorDayGroup);
      const currentRow = $(this).closest(selectorDay);
      const deleteCheckBox = currentRow.find(`#id_${currentRow.attr('data-form-prefix')}-DELETE`);
      deleteCheckBox.val(1);
      currentRow.addClass(selectorClassToDelete.substring(1));
      const lastUndeletedRow = formSet
        .find(selectorDay)
        .not(selectorClassToDelete).last();
      const previousAddButton = lastUndeletedRow
        .find(selectorButtonAdd);
      if (previousAddButton.length > 0 && previousAddButton.hasClass('hidden')) {
        previousAddButton.removeClass('hidden');
      }
      adjustAllManagementForms();
    });
  }

  function initNewRow(newRow, oldRow, newFormPrefix, existingRow) { // initDay
    const oldFormsetPrefix = oldRow
      .closest(selectorDayGroup)
      .attr('data-formset-prefix');
    const oldFormPrefix = oldRow.attr('data-form-prefix');
    newRow.attr('data-form-prefix', newFormPrefix);
    newRow.attr('data-formset-prefix', oldFormsetPrefix);
    newRow.removeClass(selectorClassToDelete.substring(1));
    const deleteCheckBox = newRow.find(`#id_${newRow.attr('data-form-prefix')}-DELETE`);
    deleteCheckBox.val('False');
    const fieldsToAdjust = [
      fieldNameDay,
      fieldNameTimeFrom,
      fieldNameTimeto,
      fieldNameType,
      fieldNameDelete,
    ];
    for (let i = fieldsToAdjust.length - 1; i >= 0; i -= 1) {
      const oldField = oldRow.find(`[name="${oldFormPrefix}-${fieldsToAdjust[i]}"]`);
      const newName = oldField.attr('name').replace(oldFormPrefix, newFormPrefix);
      const newField = newRow.find(`[name="${fieldsToAdjust[i]}"]`);
      const newFieldID = newField.attr('id');
      const modifiedID = `id_${newName}`;
      newField.attr('name', newName);
      newField.attr('id', modifiedID);
      if (fieldsToAdjust[i] === 'day') {
        newField.attr('value', oldField.val());
      }
      newRow.find(`[for="${newFieldID}"]`).attr('for', modifiedID);
    }
    if (!existingRow) {
      const addButton = newRow.find(selectorButtonAdd);
      const deleteButton = newRow.find(selectorButtonDelete);
      if (addButton.length > 0) {
        initAddButton(addButton);
      }
      if (deleteButton.length > 0) {
        initDeleteButton(deleteButton);
      }
    }
  }

  function initOutOfOffice(button) {
    button.click(function onClick() {
      const currentRow = $(this).closest(selectorDay);
      const currentTBody = $(this).closest('tbody');
      const checked = $(this).is(':checked');
      const buttonAdd = currentTBody.find(selectorButtonAdd);
      const timeFrom = currentRow.find(selectorFieldTimeFrom);
      const timeTo = currentRow.find(selectorFieldTimeTo);
      const type = currentRow.find(selectorFieldType);

      timeFrom.prop('disabled', checked);
      timeTo.prop('disabled', checked);
      type.prop('disabled', checked);
      currentTBody.find(selectorButtonDelete).click();
      if (checked) {
        buttonAdd.hide();
        buttonAdd.addClass('hidden');
        timeFrom.val('09:00');
        timeTo.val('17:00');
        type.val(null);
      }
      else {
        buttonAdd.show();
        buttonAdd.removeClass('hidden');
        timeFrom.prop('readOnly', false);
        timeTo.prop('readOnly', false);
        type.prop('readOny', false);
        type.val(1);
      }
    });
  }

  function initTypeSelect(select) {
    select.on('change', function initSelect() {
      const type = parseInt($(this).val(), 10);

      const currentGroup = $(this).closest(selectorDayGroup);
      const defaultTimes = attendanceData.defaultTimes[currentGroup.index() - 1];
      const currentRow = $(this).closest(selectorDay);
      const timeFrom = currentRow.find(selectorFieldTimeFrom);
      const timeTo = currentRow.find(selectorFieldTimeTo);
      currentRow.find(`${selectorFieldTimeFrom}, ${selectorFieldTimeTo}`)
        .prop('readonly', type === AttendanceType.sickday);
      if (type === AttendanceType.sickday) {
        timeFrom.val(defaultTimes.nonwork[0]);
        timeTo.val(defaultTimes.nonwork[1]);
      }
      else if (attendanceData.typesWithBreak.indexOf(type) === -1) {
        timeFrom.val(defaultTimes.nonwork[0]);
        timeTo.val(defaultTimes.nonwork[1]);
      }
      else if (type === AttendanceType.toilBankHoliday) {
        timeFrom.val(defaultTimes.work[0]);
        timeTo.val(defaultTimes.work[1]);
      }
    });
  }

  function initAllRows() { // Will be called only once
    initAddButton($(selectorButtonAdd).filter(function addButton() {
      return $(this).parents(selectorEmptyForm).length === 0;
    }));
    initDeleteButton($(selectorButtonDelete).filter(function deleteButton() {
      return $(this).parents(selectorEmptyForm).length === 0;
    }));
    initOutOfOffice($(selectorOutOfOffice));
    initTypeSelect($(selectorFieldType));
  }

  function init(data) {
    attendanceData = data;
    removeErrorsFromCopyForm();
    initAllRows();
  }

  return {
    init,
  };
}());
