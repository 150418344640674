function isCafeteriaEmpty(fields) {
  const defaultFields = [
    'id_job_family',
    'id_reference_level',
    'id_target_bonus',
    'id_market_midpoint_0',
    'id_comparative_ratio',
    'id_contract_hours_ratio',
    'id_cafeteria_level_budget',
  ];
  const fieldsToCheck = fields || defaultFields;
  return fieldsToCheck.every((field) => {
    const el = $(`#${field}`);
    const val = $.trim(el.val());
    return !((el.attr('disabled') === undefined) && (val.length > 0));
  });
}

function hideFields(fields) {
  fields.forEach((field) => {
    $(`#${field}`).parent('div').hide();
  });
}

function setFieldValue(id, value) {
  const el = $(`#${id}`);
  if (el.length === 0) {
    return;
  }

  const selectize = el[0].selectize;

  // set value
  if (selectize) {
    selectize.setValue(value.toString());
  }
  else {
    el.val(value);
  }

  // care about material design layout
  el.parent('div').removeClass('has-error');
  if (value !== '') {
    el.parent('div').removeClass('is-empty');
  }
  else {
    el.parent('div').addClass('is-empty');
  }
}

function clearFields(fields) {
  fields.forEach((field) => {
    setFieldValue(field, '');
  });
}

$(() => {
  // -- cafeteria: dynamic show/hide form fields

  if (isCafeteriaEmpty()) {
    // whole cafeteria form is empty, no market & benefits was set for this employee
    // => user must fill whole set of information now (except readonly fields which became
    //    visible after form submit and CafeteriaHistory save)
    hideFields(['id_comparative_ratio']);
    hideFields(['id_contract_hours_ratio']);
  }

  // -- cafeteria: prefill according to job family and reference level

  $('#id_job_family, #id_reference_level').on('change', () => {
    const jobFamilyValue = $('#id_job_family').val();
    const referenceLevelValue = $('#id_reference_level').val();

    if ((jobFamilyValue.length > 0) && (referenceLevelValue.length > 0)) {
      // both values set, lets ask api about values

      $.ajax({
        type: 'get',
        url: `/api/v1/cafeteria/config/?job_family=${jobFamilyValue}&reference_level=${referenceLevelValue}`,
        success(data) {
          if (data.length) {
            setFieldValue('id_market_midpoint_0', data[0].market_midpoint);
            setFieldValue('id_target_bonus', data[0].target_bonus);
            setFieldValue('id_cafeteria_level_budget', data[0].cafeteria_level_budget.id);
            clearFields(['id_comparative_ratio']);
          }
          else {
            clearFields([
              'id_market_midpoint_0',
              'id_target_bonus',
              'id_comparative_ratio',
              'id_cafeteria_level_budget',
            ]);
          }
        },
      });
    }
  });
});
