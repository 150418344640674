// BEWARE: This widget is built specifically for attendance
const regTime = /^[0-9]{1,2}:[0-9]{1,2}$/;
const Timepicker = {
  title_main: 'Choose time',
  title_minute_picker: 'Choose a minute',
  title_hour_picker: 'Choose an hour',
  minuteInterval: null, // Minutes, which must be loaded from the template context.
  minuteBorderline: 59,
  nullFun() {},
  twoDigit(num) {
    let twoDigit = String(num);
    while (twoDigit.length < 2) {
      twoDigit = `0${twoDigit}`;
    }
    return twoDigit;
  },
  generateSingleOption(type, value) {
    // type parameter can be 'minute' or 'hour'
    return `<li class="cell-2 js-${type}-cell" data-val="${value}">${value}</li>`;
  },
  generateHourWidgetHTML() {
    const output = [];
    for (let i = 0; i < 24; i += 1) {
      output.push(this.generateSingleOption('hour', this.twoDigit(i)));
    }
    return output.join('');
  },
  generateMinuteWidgetHTML() {
    const output = [];
    for (let i = 0; i < 60; i += this.minuteInterval) {
      output.push(this.generateSingleOption('minute', this.twoDigit(i)));
    }
    return output.join('');
  },
  generateContent() {
    this.content = $(`<div class="timepicker">\
                    <div v-show class="title">${this.title_main}</div>\
                        <div class="choose-all">\
                            <div class="handle">\
                                <div class="cell-4"><a class="icon-up glyphicon glyphicon-chevron-up js-plus-hour"></a></div>\
                                <div class="cell-2"></div>\
                                <div class="cell-4"><a class="icon-up glyphicon glyphicon-chevron-up js-plus-minute"></a></div>\
                            </div>\
                            <div class="text">\
                                <div class="cell-4"><a class="js-hour-show" title="${this.title_hour_picker}"></a></div>\
                                <div class="cell-2">:</div>\
                                <div class="cell-4"><a class="js-minute-show" title="${this.title_minute_picker}"></a></div>\
                            </div>\
                            <div class="handle">\
                                <div class="cell-4"><a class="icon-down glyphicon glyphicon-chevron-down js-minus-hour"></a></div>\
                                <div class="cell-2"></div>\
                                <div class="cell-4"><a class="icon-down glyphicon glyphicon-chevron-down js-minus-minute"></a></div>\
                            </div>\
                        </div>\
                        <div class="choose-hour">\
                            <ul class="handle">${this.generateHourWidgetHTML()}</ul>\
                        </div>\
                        <div class="choose-minute">\
                            <ul class="handle">${this.generateMinuteWidgetHTML()}</ul>\
                        </div>\
                    </div>\
                </div>`);
  },
  initDom() {
    // eslint-disable-next-line no-script-url
    this.content.find('a').attr('href', 'javascript:void(0);');
    this.title = this.content.find('.title');
    this.chooseAll = this.content.find('.choose-all');
    this.chooseMinute = this.content.find('.choose-minute');
    this.chooseHour = this.content.find('.choose-hour');
    this.hourShow = this.content.find('.js-hour-show');
    this.minuteShow = this.content.find('.js-minute-show');
  },
  update() {
    this.inputTarget.val(`${this.twoDigit(this.hour)}:${this.twoDigit(this.minute)}`);
    this.minuteShow.text(this.twoDigit(this.minute));
    this.hourShow.text(this.twoDigit(this.hour));
    this.inputTarget.$timepickerUpdate();
    this.inputTarget.change();
    return this;
  },
  handleBorderlineMinute() {
    let extraMinute = this.chooseMinute.find('.handle .js-minute-extra-cell');
    const displayExtraMinute = this.hour === 23;
    if (extraMinute.length > 0 && !displayExtraMinute) {
      extraMinute.remove();
    }
    else if (extraMinute.length === 0 && displayExtraMinute) {
      extraMinute = $(this.generateSingleOption('minute-extra', this.minuteBorderline));
      extraMinute.on('click', Timepicker.minuteCell);
      this.chooseMinute.find('.handle').append(extraMinute);
    }
  },
  flatMinutesIfNotBorderHour() {
    if (this.minute === this.minuteBorderline && this.hour !== 23) {
      this.minute = Math.floor(this.minuteBorderline / this.minuteInterval) * this.minuteInterval;
    }
  },
  minusMinute() {
    let minuteDecrement = Timepicker.minuteInterval;
    if (Timepicker.minute === Timepicker.minuteBorderline) {
      minuteDecrement -= 60 - Timepicker.minuteBorderline;
    }
    Timepicker.minute -= minuteDecrement;
    if (Timepicker.minute < 0) {
      if (Timepicker.hour === 23) {
        Timepicker.minute = Timepicker.minuteBorderline;
      }
      else {
        Timepicker.minute = 60 - Timepicker.minuteInterval;
      }
    }
    Timepicker.update();
  },
  plusMinute() {
    if (Timepicker.hour === 23 && Timepicker.minute === 60 - Timepicker.minuteInterval) {
      Timepicker.minute = Timepicker.minuteBorderline;
    }
    else {
      Timepicker.minute += Timepicker.minuteInterval;
      if (Timepicker.minute >= Timepicker.minuteBorderline) {
        Timepicker.minute = 0;
      }
    }
    Timepicker.update();
  },
  plusHour() {
    Timepicker.hour += 1;
    if (Timepicker.hour > 23) {
      Timepicker.hour = 0;
    }
    Timepicker.flatMinutesIfNotBorderHour();
    Timepicker.update();
  },
  minusHour() {
    Timepicker.hour -= 1;
    if (Timepicker.hour < 0) {
      Timepicker.hour = 23;
    }
    Timepicker.flatMinutesIfNotBorderHour();
    Timepicker.update();
  },
  minuteCell() {
    Timepicker.minute = Number(this.getAttribute('data-val'));
    Timepicker.update();
    Timepicker.chooseMinute.hide();
    Timepicker.chooseAll.show();
    Timepicker.title.text(Timepicker.title_main);
  },
  hourCell() {
    Timepicker.hour = Number(this.getAttribute('data-val'));
    Timepicker.flatMinutesIfNotBorderHour();
    Timepicker.update();
    Timepicker.chooseHour.hide();
    Timepicker.chooseAll.show();
    Timepicker.title.text(Timepicker.title_main);
  },
  normalizeFieldTimeValue(field, timepickerObj) {
    // This function keeps value of fields valid.
    let val = field.value;
    if (regTime.test(val)) {
      val = val.split(':');
      const hour = Math.min(Number(val[0]), 23);
      const minuteLimit = hour === 23
        ? Timepicker.minuteBorderline
        : (Math.floor(
          Timepicker.minuteBorderline / Timepicker.minuteInterval) * Timepicker.minuteInterval
        );
      const inputMinute = Number(val[1]);
      const minute = Math.min(
        Math.round(inputMinute / Timepicker.minuteInterval) * Timepicker.minuteInterval,
        minuteLimit,
      );
      timepickerObj.hour = hour; // eslint-disable-line no-param-reassign
      timepickerObj.minute = minute; // eslint-disable-line no-param-reassign
    }
    timepickerObj.update();
  },
  bindEvent() {
    if (this.hasBind) {
      return;
    }
    this.hasBind = true;
    this.content.on('click', '.js-minus-minute', Timepicker.minusMinute,
    ).on('click', '.js-plus-minute', Timepicker.plusMinute,
    ).on('click', '.js-plus-hour', Timepicker.plusHour,
    ).on('click', '.js-minus-hour', Timepicker.minusHour,
    )
      .on('click', '.js-minute-cell', Timepicker.minuteCell,
      )
      .on('click', '.js-hour-cell', Timepicker.hourCell,
      )
      .on('click', (e) => {
        e.stopPropagation();
      });
    this.hourShow.on('click', () => {
      Timepicker.chooseAll.hide();
      Timepicker.chooseHour.show();
      Timepicker.title.text(Timepicker.title_hour_picker);
    });
    this.minuteShow.on('click', () => {
      Timepicker.handleBorderlineMinute();
      Timepicker.chooseAll.hide();
      Timepicker.chooseMinute.show();
      Timepicker.title.text(Timepicker.title_minute_picker);
    });
  },
  jQueryExtend() {
    $.timepicker = this;
    $.fn.timepicker = function timePicker() {
      const t = this;
      const timepickerObj = $.timepicker;
      const $body = $('html');
      if (!this[0].nodeName || this[0].nodeName !== 'INPUT') {
        return;
      }
      this.$timepickerUpdate = Timepicker.nullFun;
      this.off('click').on('click', function onClick(e) {
        if ($(this).prop('readonly')) {
          return;
        }

        timepickerObj.inputTarget = t;
        timepickerObj.normalizeFieldTimeValue(this, timepickerObj);
        const left = `${this.offsetLeft}px`;
        const top = `${this.offsetTop + this.offsetHeight}px`;
        timepickerObj.content.appendTo(this.offsetParent).css({ left, top });
        timepickerObj.chooseAll.show();
        timepickerObj.chooseHour.hide();
        timepickerObj.chooseMinute.hide();
        $.timepicker.bindEvent();
        e.stopPropagation();
        $body.one('click', () => {
          timepickerObj.content.off().remove();
          timepickerObj.hasBind = false;
        });
      });
      this.off('focusout').on('focusout', function focusOut() {
        timepickerObj.inputTarget = t;
        timepickerObj.normalizeFieldTimeValue(this, timepickerObj);
      });
      this.update = function Update(fun) {
        if ($.isFunction(fun)) this.$timepickerUpdate = fun;
        else this.$timepickerUpdate = this.nullFun;
      };
      return this; // eslint-disable-line consistent-return
    };
  },
  init() {
    this.generateContent();
    this.initDom();
    this.jQueryExtend();
  },
};
