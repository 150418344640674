function cloneFormset(number, emptyFormset) {
  const formsetWrapper = emptyFormset.closest('.js-formset-wrapper');
  const formsetprefix = formsetWrapper.data('formset-prefix');
  const newFormset = emptyFormset.clone();

  const pattern = new RegExp(`(\\w*${formsetprefix})-(\\d+)-(\\w+)`, 'gi');
  const replaced = newFormset.prop('outerHTML').replace(pattern, `$1-${number}-$3`);

  return replaced;
}

$(document).ready(() => {
  // hide DELETE checkboxes
  $('.js-formset-wrapper [id$=DELETE]').parents('.form-group').hide();

  // hide deleted forms
  $('[id$=DELETE]:checked').parents('.js-sort-item').hide();

  // remove form
  $(document).on('click', '.js-delete-form', function removeForm() {
    const formsetWrapper = $(this).closest('.js-formset-wrapper');
    const $parentsortitem = $(this).parents('.js-sort-item');
    const visibleItemCounter = formsetWrapper.find('.js-sort-item:visible').length;

    // cant remove if there is only one formset
    if (visibleItemCounter <= 1) {
      $parentsortitem.parent().effect('shake');
      return;
    }

    $parentsortitem.effect('drop', () => {
      $parentsortitem.find('[id$=DELETE]').prop('checked', true);
    });
  });

  // add form
  $(document).on('click', '.js-add-form', function addForm() {
    const formsetWrapper = $(this).closest('.js-formset-wrapper');
    const formsetprefix = formsetWrapper.data('formset-prefix');
    const oldRow = formsetWrapper.find('.js-sort-item:last');
    const totalItemCounter = formsetWrapper.find('.js-sort-item').length;

    // selectize needs to be destroy for proper cloning
    oldRow.find('.selectized').each(function destroySelectize() {
      const value = $(this).val(); // fix wrong selectize value on destroy
      this.selectize.destroy();
      $(this).val(value);
    });

    const newRow = $(cloneFormset(totalItemCounter, oldRow));
    oldRow.after(newRow);

    $(`#id_${formsetprefix}-TOTAL_FORMS`).attr('value', totalItemCounter + 1);

    // @TODO refactor, should be handled by events listening for triggers.
    oldRow.find('select').selectize({});
    newRow.find('select').selectize({});
    newRow.find('.datepicker').removeClass('hasDatepicker').datepicker({ dateFormat: 'dd.mm.yy' });
    App.ClipBoardHelper.init();
  });
});
