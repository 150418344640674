App.GroupChecker = {
  groupSelector: '.js-select-group',
  fieldSelector: '.js-select-field',

  handleGroupCheck(event) {
    const element = $(event.target);
    const target = $(element.data('target'));
    const itemsToHandle = target.find(`${this.fieldSelector} input:checkbox, input${this.fieldSelector}:checkbox`);

    return (element.is(':checked'))
      ? itemsToHandle.prop('checked', true).change()
      : itemsToHandle.prop('checked', false).change();
  },
  registerEvents() {
    $(this.groupSelector).on('change', $.proxy(this.handleGroupCheck, this));
  },
};
App.GroupChecker.registerEvents();
