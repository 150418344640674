App.Tickets = (function init() {
  // Tickets globals:
  let customFieldsValuesFormTarget = '';
  let customFieldsValuesFormUrl = '';
  let customFieldsValuesFormTicketId;

  const showHideSelect = function showHideSelect(id, action) {
    const el = $(`#${id}`);
    if (id === 'id_category') {
      el.closest('div').addClass('required');
    }

    if (action === 'show') {
      el.closest('div').show();
    }
    else {
      el.closest('div').hide();
    }
    return el;
  };

  const toggleEmpty = function toggleEmpty(el, action) {
    if (action === 'remove') {
      el.removeClass('is-empty');
    }
    else {
      el.addClass('is-empty');
    }
  };

  const setFieldValue = function setFieldValue(id, value) {
    const el = $(`#${id}`);
    const selectize = el[0].selectize;
    // set value
    if (selectize) {
      selectize.setValue(value.toString());
    }
    else {
      el.val(value);
    }
    // care about material design layout
    el.parent('div').removeClass('has-error');
    if (value !== '') {
      toggleEmpty(el.parent('div'), 'remove');
    }
    else {
      toggleEmpty(el.parent('div'), 'add');
    }
  };

  const showHideImpact = function showHideImpact(type) {
    let action = 'hide';
    if (type !== '' && type === 3) {
      action = 'show';
    }
    else {
      // eslint-disable-next-line no-undef
      setFieldValue('id_impact', default_impact);
    }
    showHideSelect('id_impact', action);
  };

  const addMessage = function addMessage(type, msg) {
    $('#messages').append(`<div class='alert alert-${type
    } alert-dismissable'><button type='button' class='close' data-dismiss='alert' ` +
            `aria-hidden='true'>×</button>${msg}</div>`);
  };

  const cleanMessages = function cleanMessages() {
    if ($('#messages').children().size() > 0) $('#messages').children('div.alert').remove();
  };

  const slideAfterFileRemove = function slideAfterFileRemove() {
    $(this).closest('div.file').slideUp('slow', function slideUpRemove() {
      $(this).remove();
    },
    );
  };

  const removeWarningOnCancel = function removeWarningOnCancel() {
    $('.warning').removeClass('warning');
  };

  const approveDeclineTicket = function approveDeclineTicket() {
    if (!$(this).closest('tr').hasClass('row_thead') && !$(this).hasClass('glyphicon-comment')) {
      $(this).closest('tr').addClass('warning');
    }
    const ticket = $(this).data().ticket;
    const action = $(this).data().action;
    const assignedUserVal = $(this).data().assignedUser;
    const url = $(this).data().url;
    if (ticket) {
      $('.modal-header .ticket-id').html(ticket);
    }
    if (action === 'decline') {
      $('.assign-user').hide();
      $('.reason-decline').show();
    }
    else {
      $('.reason-decline').hide();
      $('.assign-user').show();

      // change initial option on *ticket table view* for approve action
      if (assignedUserVal !== undefined) {
        const assignedUser = $('#approve-decline-modal-form select#id_assigned_user');
        const selectizeAssignedUser = assignedUser[0].selectize;
        selectizeAssignedUser.setValue(assignedUserVal.toString());
        assignedUser.parent('div').removeClass('is-empty');
      }
    }
    $('.modal-header .approve-decline').html(action);
    $('#approve-decline-modal-form').attr('action', url);
  };

  const disableSubmitWhileSubmitting = function disableSubmitWhileSubmitting() {
    $(this).find("button[type='submit']").prop('disabled', true);
  };

  const updateUrgencySelect = function updateUrgencySelect(data) {
    const urgency = $('#id_urgency');
    if (urgency) {
      const urgencySelectize = urgency[0].selectize;
      const val = urgencySelectize.getValue();
      urgencySelectize.clear();
      urgencySelectize.clearOptions();
      for (let i = 0; i < data.length; i += 1) {
        urgencySelectize.addOption({ value: data[i].urgency, text: data[i].label });
      }
      urgencySelectize.setValue(val);
    }
  };

  const onChangeParentCategory = function onChangeParentCategory() {
    const category = $(this); // Category
    const subcategory = $('#id_category'); // Subcategory
    const idParentCategoryVal = category.val();
    let type = ''; // Category Type
    const descriptionInput = $('#id_description'); // Description

    const selectize = category.data('selectize');

    $('.js-parent-category-error').hide();
    if (!selectize.options[selectize.getValue()].permission) {
      $('.js-parent-category-error').show();
    }

    if (idParentCategoryVal !== '') {
      $.ajax({
        type: 'get',
        url: `/api/v1/tickets/category/?id=${idParentCategoryVal}`,
        success(data) {
          if (data.length > 0) {
            $('#id_alert_message').html(data[0].message);

            setFieldValue('id_is_public', data[0].public ? 'True' : 'False');
            App.toggleField('#id_is_public', !data[0].can_change_visibility_policy);

            if (data[0].template !== '') {
              App.TrixHelpers.replaceContentForInput(descriptionInput, data[0].template);
              toggleEmpty(descriptionInput.parent('div'), 'remove');
            }
            else {
              App.TrixHelpers.replaceContentForInput(descriptionInput, '');
              toggleEmpty(descriptionInput.parent('div'), 'add');
            }

            updateUrgencySelect(data[0].sla_list);

            type = data[0].type;
            $.ajax({
              type: 'get',
              url: `/api/v1/tickets/category/?parent_category=${idParentCategoryVal}`,
              success(subCategoryData) {
                // it is necessary to clean/clean options
                const selectizeCat = subcategory[0].selectize;
                selectizeCat.clear();
                selectizeCat.clearOptions();
                if (subCategoryData.length > 0) {
                  for (let i = 0; i < subCategoryData.length; i += 1) {
                    selectizeCat.addOption({
                      value: subCategoryData[i].id,
                      text: subCategoryData[i].name,
                    });
                  }
                  // It has Category and Subcategory should show Subcategory
                  showHideSelect('id_category', 'show');
                  // If Type is IDEA should show Impact; hide otherwise
                  showHideImpact(type);
                }
                else {
                  // It has Category but hasn't Subcategory should hide Subcategory
                  const el = showHideSelect('id_category', 'hide');
                  el.val('');
                  // If Type is IDEA should show Impact; hide otherwise
                  showHideImpact(type);
                }
              },
            });
          }
          else {
            const el = showHideSelect('id_category', 'hide');
            el.val('');

            const selectizeCat = subcategory[0].selectize;
            selectizeCat.clear();
            selectizeCat.clearOptions();
          }
        },
      });
    }
    else {
      $('#id_alert_message').html('');
      App.TrixHelpers.replaceContentForInput(descriptionInput, '');
      toggleEmpty(descriptionInput.parent('div'), 'add');

      // Clean Category should hide Impact and Subcategory
      showHideImpact(type);
      const el = showHideSelect('id_category', 'hide');
      el.val('');
      const selectizeCat = subcategory[0].selectize;
      selectizeCat.clear();
      selectizeCat.clearOptions();
    }
  };

  const onChangeMainCategory = function onChangeMainCategory() {
    let idCategoryVal = $(this).val();
    const descriptionInput = $('#id_description');

    if (idCategoryVal === '') {
      idCategoryVal = $('#id_parent_category').val();
    }
    if (idCategoryVal !== '') {
      // ajax get custom fields values form html
      if (customFieldsValuesFormUrl && customFieldsValuesFormTarget) {
        let fieldSetUrl = `${customFieldsValuesFormUrl}?category=${idCategoryVal}`;
        if (customFieldsValuesFormTicketId) {
          fieldSetUrl += `&ticket=${customFieldsValuesFormTicketId}`;
        }
        $.ajax({
          type: 'get',
          url: fieldSetUrl,
          success(data) {
            $(customFieldsValuesFormTarget).html(data);
            App.init(customFieldsValuesFormTarget);
          },
        });
      }

      // ajax get category info
      $.ajax({
        type: 'get',
        url: `/api/v1/tickets/category/?id=${idCategoryVal}`,
        success(data) {
          if (data.length > 0) {
            $('#id_alert_message').html(data[0].message);

            setFieldValue('id_is_public', data[0].public ? 'True' : 'False');
            App.toggleField('#id_is_public', !data[0].can_change_visibility_policy);

            if (data[0].template !== '') {
              App.TrixHelpers.replaceContentForInput(descriptionInput, data[0].template);
              toggleEmpty(descriptionInput.parent('div'), 'remove');
            }
            else {
              App.TrixHelpers.replaceContentForInput(descriptionInput, '');
              toggleEmpty(descriptionInput.parent('div'), 'add');
            }

            updateUrgencySelect(data[0].sla_list);

            if (idCategoryVal !== $('#id_parent_category').val()) {
              if (data[0].message === '' || data[0].template === '') {
                const idParentCategoryVal = $('#id_parent_category').val();
                const message = data[0].message;
                const template = data[0].template;
                $.ajax({
                  type: 'get',
                  url: `/api/v1/tickets/category/?id=${idParentCategoryVal}`,
                  success(subCategoryData) {
                    if (subCategoryData.length > 0) {
                      if (message === '') {
                        if (subCategoryData[0].message !== '') {
                          $('#id_alert_message').html(subCategoryData[0].message);
                        }
                        else {
                          $('#id_alert_message').html('');
                        }
                      }

                      if (template === '') {
                        if (subCategoryData[0].template !== '') {
                          App.TrixHelpers.replaceContentForInput(
                            descriptionInput, subCategoryData[0].template,
                          );
                          toggleEmpty(descriptionInput.parent('div'), 'remove');
                        }
                        else {
                          App.TrixHelpers.replaceContentForInput(descriptionInput, '');
                          toggleEmpty(descriptionInput.parent('div'), 'add');
                        }
                      }
                    }
                  },
                });
              }
            }
          }
        },
      });
    }
  };

  const onCategoryChange = function onCategoryChange() {
    App.showWaitOverlay();
    const form = $(this).closest('form');
    const request = $.ajax({
      type: 'POST',
      url: form.attr('action'),
      data: form.serialize(),
      dataType: 'html',
    });

    request.done((response) => {
      App.Replace.fromDataBySelector(form, `#${form.attr('id')}`, response);
      App.init();
      App.cleanMessages();
      App.hideWaitOverlay();
      $('body').trigger('initialize-dropbox-shit-solution');
    });
    request.fail(() => {
      console.log('fail'); // eslint-disable-line no-console
    });
  };

  const setNewStatusAndSubmitComment = function setNewStatusAndSubmitComment() {
    // unify into single function  ticket_statuses
    const status = this.getAttribute('data-status');
    $('#new_status').val(status);
    $('#submit-all').click();
  };

  const deleteCategory = function deleteCategory() {
    if (!$(this).closest('tr').hasClass('row_thead')) {
      $(this).closest('tr').addClass('warning');
    }
    const categoryName = $(this).data().categoryname;
    const url = $(this).data().url;
    const pk = $(this).data().pk;
    $('.modal-header .category-name').html(categoryName);
    $('#delete-category-modal-form').attr('action', url);
    $('#delete-category-modal-form').attr('pk', pk);
  };

  const deleteCategoryModalSubmit = function deleteCategoryModalSubmit(event) {
    event.preventDefault();
    const $form = $(this);

    $.ajax({
      type: $form.attr('method'),
      url: $form.attr('action'),
      data: $form.serialize(),

      success(data) {
        $.each(data.ids, (index, value) => {
          const tr = $(`a[data-pk=${value}]`).closest('tr');
          tr.fadeOut('normal', () => {
            tr.remove();
          });
        });
        $('#modal-delete-category').modal('hide');
        const cardinalityText = $('li.cardinality').text().trim();
        $('li.cardinality').text(cardinalityText.replace(
          cardinalityText.split(' ')[0], cardinalityText.split(' ')[0] - data.ids.length),
        );
        cleanMessages();
        addMessage('success', data.message);
      },
    });
  };

  function initDropzone(id, csrf, url, fnSubmit, fnSuccess) {
    Dropzone.autoDiscover = false; // eslint-disable-line no-undef
    const attachmentsMaxFilesize = $('body').data('app').attachments_max_filesize;
    // value serverUploadMaxRequestBody is only used when displaying error, 
    // actual value used is set in extras/nginx.rancher.conf as server.client_max_body_size
    const serverUploadMaxRequestBody = 100;
    let showServerUploadError = true;
    const dropZoneOptions = {
      // Prevents Dropzone from uploading dropped files immediately
      autoProcessQueue: false,
      uploadMultiple: true,
      parallelUploads: 100,
      maxFiles: 100,
      paramName: 'attachments',
      maxFilesize: attachmentsMaxFilesize, // MB
      addRemoveLinks: true,
      dictDefaultMessage: 'Drop file', // // helper message
      url,
      init() {
        const submitButton = $('#submit-all');
        const myDropzone = this;

        submitButton.on('click', () => {
          /*
          * disable submit button to prevent multiple posts
          * cant be done with .js-disable-doubleclick class - not submit button of comment form
          * */
          submitButton.prop('disabled', true);

          // if there is nothing to submit do not post
          if ((this.files.length === 0) && ($('#id_comment').val() === '')) {
            addMessage('danger', "You can't submit empty comment!");
            submitButton.prop('disabled', false);
            window.scrollTo(0, 0);
            return;
          }

          cleanMessages();
          myDropzone.options.params.csrfmiddlewaretoken = csrf;
          if (typeof (fnSubmit) === 'function') {
            fnSubmit(myDropzone);
          }
          myDropzone.processQueue();
        });
      },
      successmultiple: fnSuccess,
      accept(file, done) {
        /*
           If files together exceeds `serverUploadMaxRequestBody` (configurable in
           nginx.rancher.conf) they cannot be uploaded.
        */
        let allSize = 0;
        const mb = 1000000;

        this.files.forEach((f) => {
          allSize += f.size;
        });

        if (allSize > serverUploadMaxRequestBody * mb) {
          done(`Your files together (${(allSize / mb).toFixed(2)}Mb) 
                  exceeded maximum size ${serverUploadMaxRequestBody}Mb. 
                  NOTE: Your last file was removed.`);
        }
        else {
          done();
        }
      },
      error(file, message, xhr) {
        /* If error was raised, because file wasnt accepted, remove it from preview. */
        if (!file.accepted) {
          this.removeFile(file);
        }

        /* Normal error message */
        if (typeof xhr === 'undefined') {
          addMessage('danger', message);
        }
        else if (xhr.status === 413) {
          this.removeFile(file);
          if (showServerUploadError) {
            showServerUploadError = false;
            addMessage('danger',
              `Your files (together) exceeded maximum size ${serverUploadMaxRequestBody}Mb.`);
          }
          else {
            addMessage('danger', 'Uploading your files failed on our side. ' +
                  'Please try again later or contact someone from Django team.');
            window.scrollTo(0, 0);
            this.removeAllFiles(true);
          }
        }
        window.scroll(0, 0);
      },
    };

    const uploader = document.querySelector(id);
    // eslint-disable-next-line no-undef, no-unused-vars
    const newDropzone = new Dropzone(uploader, dropZoneOptions);
  }

  const initCustomFieldsValuesForm = function initCustomFieldsValuesForm(target, url, ticketId) {
    customFieldsValuesFormTarget = target;
    customFieldsValuesFormUrl = url;
    customFieldsValuesFormTicketId = ticketId || undefined;
  };

  const callExportURL = function callExportURL() {
    const form = $('#table-header-form');
    const url = `${$(this).attr('data-url')}?${form.serialize()}`;
    location.href = url;
  };

  function toggleCommments() {
    const $this = $(this);
    const targets = $($this.data('target'));
    targets.collapse($this.data('action'));
  }

  const registerEvents = function registerEvents() {
    $('.remove').on('click', slideAfterFileRemove);
    $('.cancel-approve-decline-ticket, .cancel-delete-category, .cancel-delete-subcategory')
      .on('click', removeWarningOnCancel);
    $('.approve-decline-ticket').on('click', approveDeclineTicket);
    $('#approve-decline-modal-form, ' +
            '#resolve-modal-form, ' +
            '#reopen-ticket-modal-form, ' +
            '#delete-category-modal-form, ' +
            '#wait-for-feedback-modal-form, ' +
            '#comment-wait-for-feedback-modal-form')
      .on('submit', disableSubmitWhileSubmitting);
    $('.js-replace-context').on('change', '.js-category-change select', onCategoryChange);

    // these two can be removed when ticket update form is refactored.
    // (do not forgot to refactor also ajax get custom fields values form html section)
    $('.js-ticket-update #id_parent_category').on('change', onChangeParentCategory);
    $('.js-ticket-update #id_category').on('change', onChangeMainCategory);
    $('#comment-wait-for-feedback, #comment-resolve').on('click', setNewStatusAndSubmitComment);
    $('.delete-category').on('click', deleteCategory);
    $('#delete-category-modal-form').on('submit', deleteCategoryModalSubmit);

    /* call export url, but with filter parameters to know which tickets to export */
    $('.btn-export').on('click', callExportURL);

    // show/hide comments in ticket history section
    $('.js-toggle-all-comments').on('click', toggleCommments);
  };

  return {
    registerEvents,
    initDropzone,
    initCustomFieldsValuesForm,
    showHideSelect,
    showHideImpact,
  };
}());

$(document).ready(() => {
  const url = window.location.href;
  const urlParamsStart = url.indexOf('?');
  if (urlParamsStart === -1) {
    return;
  }
  const output = {};
  const paramString = url.substr(urlParamsStart + 1).split('&');
  for (let i = paramString.length - 1; i >= 0; i -= 1) {
    const current = paramString[i].split('=');
    if (current.length !== 2) {
      continue; // eslint-disable-line no-continue
    }
    output[current[0]] = current[1];
  }
  if (!Object.prototype.hasOwnProperty.call(output, 'action')) {
    return;
  }
  switch (output.action) {
    case 'approve':
      $('.approve-decline-ticket[data-action="approve"]').click();
      break;
    case 'reject':
      $('.approve-decline-ticket[data-action="decline"]').click();
      break;
    default:
      break;
  }
});
