const App = {
  /**
   * Submits a form without submitting its empty fields.
   *
   * @param form form to submit
   */
  submitFormAndDisableEmpty(event) {
    event.preventDefault();
    const $form = $(this);
    const fields = $form.find(':input');

    fields.filter((i, field) => field.value === '').attr('disabled', true);
    $form.unbind().submit(); // unbind prevents infinite loop with for form.submit() events
  },
  addMessage(type, msg, clearMessages) {
    const removeMessages = typeof clearMessages === 'undefined';

    if ($('#messages').children().size() > 0 && removeMessages) {
      $('#messages').children().remove();
    }

    $('#messages').append(
      `<div class='alert alert-${type} alert-dismissable'>
        <button type='button' class='close' data-dismiss='alert' aria-hidden='true'>×</button>
        ${msg}
      </div>`,
    );
  },
  cleanMessages() {
    $('#messages').children().remove();
  },
  showWaitOverlay() {
    $('#js-wait-overlay').show();
  },
  hideWaitOverlay() {
    $('#js-wait-overlay').hide();
  },
  cmp(a, b) {
    if (a < b) {
      return -1;
    }
    else if (a > b) {
      return 1;
    }
    return 0;
  },
  selectSetValue(select, value) {
    const selectize = select[0].selectize;
    if (selectize) {
      selectize.setValue(value);
    }
    else {
      select.val(value);
    }
  },

  initTooltips(restrictionSelector) {
    $(':not(.updater-form-planned-change)[data-toggle=tooltip]', restrictionSelector).tooltip();

    // In updatedr planned changes tooltip data are located on disabled input fields which don't
    // trigger moseover event => tooltips are not fired. This is workaround to move tooltip
    // related data to parent element.
    function updateUpdaterTooltip() {
      const p = $(this).closest('div.form-group').first();
      if (typeof p === 'undefined') {
        return;
      }
      p.addClass('updater-form-planned-change');
      p.addClass('change-tooltip');
      p.attr('data-toggle', 'tooltip');
      p.attr('data-html', 'true');
      p.attr('data-original-title', $(this).attr('data-tooltip'));
      p.tooltip();

      $(this).removeClass('updater-form-planned-change');
      $(this).removeAttr('data-toggle');
    }
    $('.updater-form-planned-change[data-toggle=tooltip]', restrictionSelector)
      .each(updateUpdaterTooltip);
  },

  toggleField(selector, disable) {
    const el = $(selector);
    if (el.length === 0) {
      return true;
    }
    const selectize = el[0].selectize;

    el.prop('disabled', !!disable);
    el.attr('disabled', !!disable);

    if (selectize) {
      if (disable) {
        selectize.disable();
      }
      else {
        selectize.enable();
      }
    }
    return true;
  },

  initForms(restrictionSelector) {
    // adds listener to submit buttons to disable them on click.
    // after a user clicks on a submit button, every submit button in that form is disabled.

    const selectorDisableDoubleclick = '.js-disable-doubleclick';
    $(selectorDisableDoubleclick, restrictionSelector).on('click', function click(e) {
      const actionName = $(this).attr('name') || 'action';
      const action = $(`<input type="hidden" name="${actionName}" value="${e.target.value}">`);
      $(this).closest('form').append(action);
    });
    $(selectorDisableDoubleclick, restrictionSelector)
      .closest('form')
      .on('submit', function submit() {
        const form = $(this);
        form.find(`${selectorDisableDoubleclick}:not([disabled])`).prop('disabled', true);
      });
  },
  initDatepicker(restrictionSelector) {
    const datePicker = $('.datepicker', restrictionSelector).datepicker({ dateFormat: 'dd.mm.yy' });

    datePicker.on('paste', (e) => {
      e.preventDefault(); // disable paste into date field, to retain format
    });

    // http://stackoverflow.com/questions/662220/how-to-change-the-pop-up-position-of-the-jquery-datepicker-control/37591905
    // ugly hack to reposition datepicker popup window
    // without following code it will be displayed over place, where is
    // normally showed error message; so it is not possible to find what
    // is wrong with given date
    datePicker.focusin(function onFocusIn() {
      const popup = $(this).offset();
      const popupTop = popup.top + 72;
      setTimeout(() => {
        $('.datepicker').datepicker('widget').css({ top: popupTop });
      }, 1);
    });
  },
  init(restrictionSelector) {
    // wraps all JS initializations
    // should be called inside $(document).ready function and also when new html is dynamically
    // added with particular restriction selector (to re-init only for new html)

    $.material.init(restrictionSelector);

    // not:binary-select is a workaround for filters, where selectize mess up stuff
    // remove after refactoring the filter code.
    $('select:not(.js-prevent-selectize, .js-prevent-selectize *, .binary-select)', restrictionSelector).selectize({});

    App.ClipBoardHelper.init();
    App.initTooltips(restrictionSelector);
    App.initForms(restrictionSelector);
    App.initDatepicker(restrictionSelector);
    App.toggleField(restrictionSelector);
  },
};

App.TrixHelpers = {
  getTrixEditorForId(id) {
    const trixEditor = $(`trix-editor[input="${id}"]`);
    return trixEditor.length
      ? trixEditor[0]
      : null;
  },
  getTrixEditorForInput(input) {
    const descriptionTrixEditor = this.getTrixEditorForId(input.attr('id'));
    return descriptionTrixEditor
      ? descriptionTrixEditor.editor
      : null;
  },
  replaceContentInEditor(editor, text) {
    editor.setSelectedRange([0, editor.getDocument().toString().length - 1]);
    editor.insertHTML(text);
    editor.setSelectedRange([0, 0]);
  },
  replaceContentForInput(input, text) {
    const editor = this.getTrixEditorForInput(input, text);
    return (editor)
      ? this.replaceContentInEditor(editor, text)
      : input.val(text);
  },
  toggleEditor(id, disable) {
    const editor = this.getTrixEditorForId(id);
    if (!editor) {
      return;
    }

    editor.contentEditable = !disable;
    $(editor.parentElement).find('trix-toolbar').toggle(!disable);
  },
};
