const Attachments = {
  CLASS_WRAPPER: '.js-attachment-wrapper',
  CLASS_DELETE_BUTTON: '.js-remove-attachment',
  CLASS_MODAL: '.js-remove-attachment-modal',
  CLASS_MODAL_HEADER: '.js-modal-header',
  CLASS_MODAL_NAME: '.js-attachment-name',
  CLASS_MODAL_FORM: '.js-form',
  CLASS_MODAL_SUBMIT: '.js-submit',
  ATTR_ATTACHMENT_ID: 'data-attachment-id',
  ID_HISTORY_PROCESS_TICKET: '#history-process-ticket',
  initDeleteButtons() {
    $(this.CLASS_WRAPPER).find(this.CLASS_DELETE_BUTTON).on('click', function deleteButtons() {
      const data = $(this).data();
      const targetModal = $(data.target);
      targetModal
        .find(`${Attachments.CLASS_MODAL_HEADER} ${Attachments.CLASS_MODAL_NAME}`)
        .html(data.attachment_name);
      targetModal
        .find(Attachments.CLASS_MODAL_FORM)
        .attr('action', data.url);
      targetModal
        .find(Attachments.CLASS_MODAL_FORM)
        .attr(Attachments.ATTR_ATTACHMENT_ID, data.attachmentId);
    });
  },
  initModalSubmit() {
    $(this.CLASS_MODAL).find(this.CLASS_MODAL_FORM).submit(function modalSubmit(event) {
      event.preventDefault();
      const modalForm = $(this);
      const removeButton = modalForm.find(Attachments.CLASS_MODAL_SUBMIT);
      const attachmentClassName = modalForm.attr(Attachments.ATTR_ATTACHMENT_ID);
      // we must gather all data from form here -
      // after serialization below, form object will be no longer accessible
      removeButton.prop('disabled', true);
      $.ajax({
        context: modalForm.closest(Attachments.CLASS_MODAL),
        type: modalForm.attr('method'),
        url: modalForm.attr('action'),
        data: modalForm.clone().serialize(),
        success(data) {
          const attachment = $(`.${attachmentClassName}`);
          const fileName = attachment.text().trim();
          attachment.after(`<li><del>${fileName}</del></li>`);
          attachment.remove();
          this.modal('hide');
          $(Attachments.ID_HISTORY_PROCESS_TICKET).prepend(data);
          const messages = $('#messages');
          if (messages.children().size() > 0) {
            messages.children('.alert-success').remove();
          }
          // This cant be used, because it is defined in Ticket namespace
          // addMessage('success', 'The attachment has been removed successfully.');
          removeButton.prop('disabled', false);
        },
      });
    });
  },
  init() {
    this.initDeleteButtons();
    this.initModalSubmit();
  },
};

Attachments.init();
