App.Replace = (function init() {
  const fromDataBySelector = function fromDataBySelector(toReplace, replacementSelector, data) {
    const $data = $(data);
    let replaceData;

    replaceData = $data.filter(replacementSelector);
    if (replaceData.length === 0) {
      replaceData = $data.find(replacementSelector);
    }
    if (replaceData.length === 0) {
      // 'Replacement not found in data';
      return false;
    }

    toReplace.replaceWith(replaceData);
    return $(replaceData);
  };

  return {
    fromDataBySelector,
  };
}());
