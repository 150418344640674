// URGENT TODO: Please refactor this. It is excruciating pain to read this code.

(function filerHeader() {
  const _hierarchyItemClass = 'js-hierarchy-item-name';
  const _defaultMaxLevel = 3;
  /**
     * Returns the value of a given URL parameter.
     *
     * @param paramName The name of the URL parameter the value of which to get.
     * @param searchUrl The search sub-string of an URL to operate on.
     * @return The parameter's value or an empty string.
     */
  function getLocationParam(paramName, searchUrl = window.location.search) {
    const param = searchUrl.substr(1).split('&')
      .map(param => param.split('=')) // eslint-disable-line no-shadow
      .find(([name, value]) => name === paramName); // eslint-disable-line no-unused-vars
    return param ? param[1] : '';
  }

  /**
     * Returns a class attribute value with desired class names.
     *
     * @param classMap A plain object of class names mapped to truth values.
     * @return Whitespace-joined desired class names.
     */
  function classIf(classMap) {
    return Object.keys(classMap).filter(className => classMap[className]).join(' ');
  }

  /**
     * Creates an element and appends it to a parent element.
     *
     * @param parent The element to append the new one to.
     * @param tagName The new element's tag name.
     * @param props A dictionary of the new element's attributes.
     * @param specialAction Whether the element shall be prepended or appended after instead.
     * @return The new element.
     */
  function appendElement(parent, tagName, props = {}, specialAction = undefined) {
    const element = document.createElement(tagName);
    if (parent !== null) {
      if (specialAction === appendElement.AFTER) {
        if (parent.nextSibling !== null) {
          parent.parentNode.insertBefore(element, parent.nextSibling);
        }
        else {
          parent.parentNode.appendChild(element);
        }
      }
      else if (specialAction === appendElement.BEFORE) {
        parent.parentNode.insertBefore(element, parent);
      }
      else if (specialAction === appendElement.PREPEND && parent.firstChild !== null) {
        parent.insertBefore(element, parent.firstChild);
      }
      else {
        parent.appendChild(element);
      }
    }
    Object.assign(element, props);
    // for (const propName in props) {
    //   element[propName] = props[propName];
    // }
    return element;
  }
  appendElement.PREPEND = Symbol('prepend');
  appendElement.AFTER = Symbol('insert after');
  appendElement.BEFORE = Symbol('insert before');

  /**
     * Removes all children from an element.
     *
     * @param element The element to clear.
     */
  function clearElement(element) {
    while (element.firstChild !== null) {
      element.removeChild(element.firstChild);
    }
  }

  /**
     * Generates a random valid id for an element.
     *
     * @return The id.
     */
  function randomID() {
    return `id${String(Math.random()).substr(2)}`;
  }

  /**
     * Adds or removes or toggles a class of an element.
     *
     * @param action If the class shall be added, removed or toggled.
     * @param element The element to perform the action on.
     * @param className The class to look for.
     */
  function setClass(action, element, className) {
    const classNames = element.className ? element.className.split(' ') : [];
    const classIndex = classNames.indexOf(className);
    if (classIndex >= 0) {
      if (action === setClass.REMOVE || action === setClass.TOGGLE) {
        classNames.splice(classIndex, 1);
      }
    }
    else if (action === setClass.ADD || action === setClass.TOGGLE) {
      classNames.push(className);
    }
    element.className = classNames.join(' '); // eslint-disable-line no-param-reassign
  }
  setClass.ADD = Symbol('add class');
  setClass.REMOVE = Symbol('remove class');
  setClass.TOGGLE = Symbol('toggle class');

  /**
     * Checks for the presence of a class name in an element's className property.
     *
     * @param element The element to check.
     * @param className The class name to check for.
     */
  function hasClass(element, className) {
    return element.className.split(' ').indexOf(className) >= 0;
  }

  /**
     * Removes all text nodes that are direct descendants of a given node.
     *
     * @param parent The element to remove text nodes from.
     */
  function removeText(parent) {
    // eslint-disable-next-line no-restricted-syntax
    for (const node of parent.childNodes) {
      if (node instanceof Text) {
        parent.removeChild(node);
      }
    }
  }

  /**
     * Gets the count of checked checkboxes that are descendants of an ancestor element.
     *
     * @param ancestor The ancestor element.
     * @returns The count of checked checkboxes.
     */
  function getCheckedCount(ancestor) {
    let checkedCount = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const checkbox of ancestor.querySelectorAll('input[type=checkbox]')) {
      if (checkbox.checked) {
        checkedCount += 1;
      }
    }
    return checkedCount;
  }

  /**
     * Generates a list-view.
     *
     * @param parentList The <ul> to put the <li>'s element into.
     * @param sourceSelect The tree to get the items from.
     * @param handleCheck Checkbox check callback.
     */
  function generateListView(parentList, sourceSelect, handleCheck) {
    // eslint-disable-next-line no-restricted-syntax
    for (const option of sourceSelect.querySelectorAll('option')) {
      if (option.textContent === '---') {
        continue; // eslint-disable-line no-continue
      }
      const id = randomID();
      const item = appendElement(parentList, 'li');
      const checkbox = appendElement(item, 'input', { type: 'checkbox', id });
      checkbox.addEventListener('change', () => {
        option.selected = checkbox.checked;
        handleCheck();
      });
      if (option.selected) {
        checkbox.checked = true;
      }
      appendElement(item, 'label', { textContent: option.textContent, htmlFor: id });
    }
  }

  function sanitizeSearchString(term) {
    return term.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // remove accents
      .toLowerCase();
  }

  function strContainsTerm(searchText, searchTerm) {
    /*
     * Perform pure string search. Currently text-case and accents are ignored.
     */

    const normalizedListItem = sanitizeSearchString(searchText);
    const normalizedSearchTerm = sanitizeSearchString(searchTerm);

    return normalizedListItem.indexOf(normalizedSearchTerm) >= 0;
  }

  function termCanBeFoundIn(searchTerm, searchElement, searchInParentElements) {
  /**
    * Search for match inside specified items.
    *
    * @seachTerm - String - Term what will be looked for inside search items.
    * @searchItem - String - Its content will be matched against
    * @searchInParentElements - Boolean - Flag. If is True, search if the match is found in
    *                                     one of searchElement's parents.
    */

    let found = strContainsTerm(searchElement.textContent, searchTerm);

    if (found) {
      return found;
    }

    if (searchInParentElements) {
      found = $(searchElement).parentsUntil('.tree-view-root-list', 'li').get().some((element) => {
        const itemNameElement = Array
          .from(element.childNodes)
          .find(child => child.classList.contains(_hierarchyItemClass));

        if (!itemNameElement) {
          return false;
        }

        return strContainsTerm(itemNameElement.textContent, searchTerm);
      });
    }

    return found;
  }

  /**
     * Generates a tree-view.
     *
     * @param list The <ul> containing the <li>'s to search among.
     * @param handleBlur A function to call when the search-bar loses focus.
     */

  function listSearchBar(list, handleBlur) {
    const searchBar = appendElement(null, 'input', {
      type: 'text',
      className: 'form-control tree-view-container--search-bar',
      placeholder: 'Type for search...',
      style: 'margin-left: 16px',
    });

    list.parentNode.insertBefore(searchBar, list);

    const showMatchingItems = function showMatchingItems() {
      function defaultHierarchize() {
        const searchedTerm = searchBar.value || '';

        const listItems = list.querySelectorAll('li');

        for (let i = listItems.length - 1; i >= 0; i -= 1) {
          if (searchedTerm) {
            // Search for the term and then remove accents to compare if a match is found.

            const found = termCanBeFoundIn(searchedTerm, listItems[i], true);

            setClass(found ? setClass.REMOVE : setClass.ADD, listItems[i], 'any-view-hidden');

            if (found) {
              setClass(setClass.REMOVE, listItems[i], 'collapsed');
            }
          }
          else {
            const displayableItem = (Number(listItems[i].getAttribute('data-level')) >= _defaultMaxLevel);

            setClass(setClass.REMOVE, listItems[i], 'any-view-hidden');
            setClass(displayableItem ? setClass.ADD : setClass.REMOVE, listItems[i], 'collapsed');
          }
        }
      }

      if (Object.prototype.hasOwnProperty.call(this, 'searchTimer')) {
        clearTimeout(this.searchTimer);
      }

      this.searchTimer = setTimeout(defaultHierarchize, 300);
    };


    searchBar.addEventListener('keyup', showMatchingItems);

    searchBar.addEventListener('blur', handleBlur);
  }

  function sortTree(tree) {
    tree.sort((a, b) => {
      const textA = a.text.toUpperCase();
      const textB = b.text.toUpperCase();
      return App.cmp(textA, textB);
    });

    for (let i = 0; i < tree.length; i += 1) {
      if (tree[i].children.length > 0) {
        sortTree(tree[i].children);
      }
    }
    return tree;
  }

  /**
     * Parses a select into a tree of options.
     *
     * @param sourceSelect The select element to fetch the options from.
     */
  function parseTree(sourceSelect) {
    const tree = { text: 'All', children: [], optionElem: null };
    const options = sourceSelect.getElementsByTagName('option');
    const optLength = options.length;

    for (let x = 0; x < optLength; x += 1) {
      const option = options[x];
      const level = option.textContent.match(/^(-*)[^-]/)[1].length;
      const value = option.textContent.substr(level);
      let parentNode = tree;

      for (let i = 0; i < level; i += 1) {
        if (parentNode.children.length > 0) {
          parentNode = parentNode.children[parentNode.children.length - 1];
        }
      }

      parentNode.children.push({ text: value, children: [], optionElem: option });
    }
    return sortTree([tree])[0];
  }

  /**
     * Generates the tree-view.
     *
     * @param parent The element to put the root <li> element into.
     * @param tree The tree to get the items from.
     * @param handleCheck Checkbox check callback.
     * @param unpackedCount The number of levels to be unpacked by default.
     */
  function generateTreeView(
    parent,
    tree,
    handleCheck,
    unpackedCount = _defaultMaxLevel,
    level = 0,
  ) {
    // create the item
    const rootItem = appendElement(parent, 'li', unpackedCount > 0
      ? {}
      : { className: 'collapsed' });
    rootItem.setAttribute('data-level', level);
    // create the checkbox and its label
    const checkbox = appendElement(rootItem, 'input', { type: 'checkbox', id: randomID() });
    const caption = appendElement(rootItem, 'label', {
      htmlFor: checkbox.id,
      className: _hierarchyItemClass + (tree.children ? '' : ' empty'),
    });
    checkbox.addEventListener('change', () => {
      const subCheckBoxes = rootItem.querySelectorAll('input');
      // eslint-disable-next-line no-restricted-syntax
      for (const subCheckBox of subCheckBoxes) {
        if (subCheckBox.checked !== checkbox.checked) {
          subCheckBox.click();
        }
      }
      if (tree.optionElem !== null) {
        tree.optionElem.selected = checkbox.checked; // eslint-disable-line no-param-reassign
      }
      handleCheck();
    });

    appendElement(caption, 'span', { textContent: tree.text });

    // clone the original option's value
    if (tree.optionElem !== null && tree.optionElem.selected) {
      checkbox.checked = true;
    }
    // recursively generate the item's sub-list if passed-in tree has children
    if (tree.children.length > 0) {
      const subList = appendElement(rootItem, 'ul');
      const arrow = appendElement(rootItem, 'span', { className: 'tree-view-arrow' });
      arrow.addEventListener('click', () => setClass(setClass.TOGGLE, rootItem, 'collapsed'));
      // eslint-disable-next-line no-restricted-syntax

      tree.children.forEach((node) => {
        generateTreeView(subList, node, handleCheck, unpackedCount - 1, level + 1);
      });
    }
    else {
      rootItem.className = 'tree-view-leaf-item';
    }
  }

  /**
     * Updates a tree- or a list-view's toggle button.
     *
     * @param toggle The toggle button element.
     * @param dropdown The view's dropdown.
     */
  function updateToggle(toggle, dropdown) {
    const dropdownShown = !hasClass(dropdown, 'any-view-hidden');
    const checkedCount = getCheckedCount(dropdown);
    // eslint-disable-next-line no-param-reassign
    toggle.innerHTML = `Filters (${checkedCount}) <em>${dropdownShown ? '\u02C5' : '\u02C4'}</em>`;
    setClass(checkedCount > 0 ? setClass.ADD : setClass.REMOVE, toggle, 'nonempty');
  }

  /**
     * Initializes a tree- or a list-view.
     *
     * @param parent The element to put the toggle button in
     * @param sourceSelect The select element to fetch options from
     */
  function initView(parent, sourceSelect) {
    // figure out if a tree-view should be generated

    const isTreeOption = option => /^-+[a-zA-Z0-9]/.test(option.textContent);
    const isTreeView = [...sourceSelect.querySelectorAll('option')].find(isTreeOption) !== undefined;
    // build DOM structure
    const dropdown = appendElement(document.body, 'div', {
      className: 'any-view-dropdown any-view-hidden',
    });
    appendElement(dropdown, 'div', { className: 'any-view-header' });
    const container = appendElement(dropdown, 'div', {
      className: 'any-view-container',
      tabIndex: 1,
    });

    const rootList = appendElement(container, 'ul', { className: 'tree-view-root-list' });
    if (isTreeView) {
      // eslint-disable-next-line no-use-before-define
      generateTreeView(rootList, parseTree(sourceSelect), () => updateToggle(toggle, dropdown));

      listSearchBar(rootList, () => {
        container.focus();
        container.blur();
      });

      setClass(setClass.ADD, container, 'tree-view-container');
    }
    else {
      // eslint-disable-next-line no-use-before-define
      generateListView(rootList, sourceSelect, () => updateToggle(toggle, dropdown));
      listSearchBar(rootList, () => {
        container.focus();
        container.blur();
      });
      setClass(setClass.ADD, container, 'list-view-container');
    }
    // make the tree- or list-view pop-up when the toggle button is clicked
    const toggle = appendElement(parent, 'a', {
      href: '#',
      className: 'any-view-toggle',
    }, appendElement.PREPEND);
    updateToggle(toggle, dropdown);
    toggle.addEventListener('click', (event) => {
      event.preventDefault();
      // position the dropdown below the toggle
      const { left, bottom } = toggle.getBoundingClientRect();
      dropdown.style.left = `${left}px`;
      dropdown.style.top = `${bottom + window.scrollY}px`;
      // show and focus the dropdown
      setClass(setClass.REMOVE, dropdown, 'any-view-hidden');
      container.focus();
      // update the toggle's arrow
      updateToggle(toggle, dropdown);
    });

    // make dropdown disappear on blur, if a non-descendant is focused
    container.addEventListener('blur', () => setTimeout(() => {
      if (container.contains(document.activeElement)) {
        if (document.activeElement.type !== 'text') {
          container.focus();
        }
      }
      else {
        setClass(setClass.ADD, dropdown, 'any-view-hidden');
        updateToggle(toggle, dropdown);
      }
    }, 0));
  }

  /**
     * Makes a yes/no select highlight itself on select.
     *
     * @param select The select element to filterify.
     */
  function filterifyBinarySelect(select) {
    const highlight = () => {
      const option = select.options.item(select.options.selectedIndex);
      setClass(option && option.textContent !== '---' ? setClass.ADD : setClass.REMOVE, select, 'binary-select-selected');
    };
    setClass(setClass.ADD, select, 'binary-select');
    select.addEventListener('change', highlight);
    highlight();
  }

  /**
     * Hides the old-style selection column and creates a new one.
     *
     * @param table The table element to operate on.
     */
  function patchSelection(table) {
    // add checkbox-containing cells
    // eslint-disable-next-line no-restricted-syntax
    for (const row of table.querySelectorAll('tbody tr')) {
      const id = randomID();
      const checkboxCell = appendElement(row, 'td', {}, appendElement.PREPEND);
      const checkbox = appendElement(checkboxCell, 'input', { type: 'checkbox', className: 'cell-checkbox', id });
      appendElement(checkboxCell, 'label', { type: 'checkbox', className: 'cell-checkbox', htmlFor: id });
      window.addEventListener('load', () => {
        const checkIcon = row.querySelector('.glyphicon.select-item');
        checkbox.checked = hasClass(checkIcon, 'remove-item');
        checkbox.addEventListener('change', () => {
          checkIcon.click();
        });
      });
    }
    // add de-/select-all buttons
    const checkboxes = document.querySelectorAll('input.cell-checkbox');
    const headerRows = table.querySelectorAll('thead tr');
    appendElement(headerRows[0], 'th', {}, appendElement.PREPEND);
    const selectAllCell = appendElement(headerRows[1], 'th', { rowSpan: 2, className: 'select-all-cell' }, appendElement.PREPEND);
    appendElement(selectAllCell, 'a', { href: '#', textContent: '\u2714 all' }).addEventListener('click', (event) => {
      event.preventDefault();
      document.querySelector('a.select-all').click();
      // eslint-disable-next-line no-restricted-syntax
      for (const checkbox of checkboxes) {
        checkbox.checked = true;
      }
    });
    appendElement(selectAllCell, 'a', { href: '#', textContent: '\u2718 all' }).addEventListener('click', (event) => {
      event.preventDefault();
      document.querySelector('a.remove-all').click();
      // eslint-disable-next-line no-restricted-syntax
      for (const checkbox of checkboxes) {
        checkbox.checked = false;
      }
    });
    // hide the original selection column
    // eslint-disable-next-line no-restricted-syntax
    for (const cell of document.querySelectorAll('th.select, td.select')) {
      setClass(setClass.ADD, cell, 'any-view-hidden');
    }
  }

  (function init() {
    // get the table or halt
    const table = document.querySelector('table.js-table-header-filter');
    if (table === null) {
      return;
    }
    // swap the header rows
    const tableHead = table.querySelector('thead');
    const filterRow = tableHead.querySelector('tr:first-child');
    tableHead.removeChild(filterRow);
    tableHead.appendChild(filterRow);
    // pack cell contents in easily stylable divs
    // eslint-disable-next-line no-restricted-syntax
    for (const cell of filterRow.querySelectorAll('th')) {
      const innerHTML = cell.innerHTML;
      clearElement(cell);
      appendElement(cell, 'div', { className: 'filter-content', innerHTML });
    }
    // filterify binary selects
    // eslint-disable-next-line no-restricted-syntax
    for (const select of filterRow.querySelectorAll('select')) {
      filterifyBinarySelect(select);
    }
    // add "from - to" placeholders to twin inputs
    // eslint-disable-next-line no-restricted-syntax
    for (const cell of filterRow.querySelectorAll('.filter-content')) {
      const inputs = cell.querySelectorAll('input[type=text].form-control');

      if (inputs.length === 2) {
        removeText(cell);
        inputs[0].placeholder = 'From';
        inputs[1].placeholder = 'To';
        setClass(setClass.ADD, inputs[1], 'plain-input-second');
      }
    }
    // generate list-views and tree-views
    // eslint-disable-next-line no-restricted-syntax
    for (const select of filterRow.querySelectorAll('select[multiple=multiple]')) {
      initView(select.parentNode, select);
      setClass(setClass.ADD, select, 'any-view-hidden');
    }
    // add clear buttons to plain inputs
    // eslint-disable-next-line no-restricted-syntax
    for (const input of document.querySelectorAll('th input:not([type=checkbox])')) {
      const bdy = document.getElementsByTagName('body')[0];
      const clearButton = appendElement(bdy, 'a', { // input
        href: '#',
        className: classIf({ 'plain-input-cross': true, 'plain-input-cross-second': input.placeholder === 'To' }),
      }, appendElement.AFTER);
      clearButton.addEventListener('click', (event) => {
        event.preventDefault();
        input.value = '';
        setClass(setClass.REMOVE, clearButton, 'plain-input-cross-visible');
      });
      input.addEventListener('keyup', () => {
        setClass(input.value ? setClass.ADD : setClass.REMOVE, clearButton, 'plain-input-cross-visible');
      });
    }
    $('.js-clear-empty-fields').on('submit', App.submitFormAndDisableEmpty);

    // indicate which column is used for sorting
    const sortCrit = getLocationParam('sort');
    if (sortCrit) {
      // eslint-disable-next-line no-restricted-syntax
      for (const sortLink of table.querySelectorAll('tr:first-child th a[href]')) {
        const linkParam = getLocationParam('sort', sortLink.getAttribute('href'));
        if (linkParam === `-${sortCrit}`) {
          sortLink.textContent = `${sortLink.textContent} \u25BC`;
        }
        else if (sortCrit === `-${linkParam}`) {
          sortLink.textContent = `${sortLink.textContent} \u25B2`;
        }
      }
    }

    // add selection column if select-all button/link exists
    if (document.querySelector('.select-all')) {
      patchSelection(table);
    }
  }());
}());
